.service {
  &__header {
    padding-top: 130px;
    padding-bottom: calc(var(--basicDistance) / 2);
    margin-bottom: 80px;

    .description {
      margin-top: 24px;

      max-width: 660px;
    }
  }

  &__details,
  &__technologies {
    margin-bottom: var(--basicDistance);
  }

  &__full-list {
    li {
      position: relative;
      padding-left: 24px;
      margin-bottom: 6px;

      &:before {
        position: absolute;
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 12px;
        background: gray;
        left: 0;
        top: 6px;
        transition: $basicTransition;
      }

      a {
        text-decoration: none;
        color: inherit;
      }

      &:hover,
      &.active {
        &:before {
          transform: scale(1.5);
        }
      }
    }
  }

  &__details {
    margin-top: 120px;

    .details {
      &__item {
        margin-bottom: 56px;
      }
    }
  }
}

.section-container {
  position: relative;
}

.service__header {
  .container {
    position: relative;

    .service-title {
      &.mobile {
        display: none;
      }

      &::before {
        position: absolute;
        content: '';
        top: 100px;
        left: 0;
        width: 100vw;
        height: 10px;
        background: linear-gradient(
          270deg,
          #6baac5 -8.33%,
          #2990b1 71.22%,
          #50cbe9 100%
        );
      }

      &::after {
        position: absolute;
        content: '';
        top: 100px;
        right: 0;
        width: 100vw;
        height: 10px;
        background: linear-gradient(
          270deg,
          #6baac5 -8.33%,
          #2990b1 71.22%,
          #50cbe9 100%
        );
      }
    }
  }
}

.service__technologies {
  .container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 20px;

    @media (max-width: 1200px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 992px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.service__details {
  .container {
    .section__head {
      margin-bottom: 40px;
    }

    .services__grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-gap: 32px;
      width: 100%;
      margin: 0 auto;

      &.staff {
        grid-template-rows: repeat(1, 1fr);
      }

      .services__item {
        &-card {
          padding: 32px !important;
        }
      }
    }
  }
}

.other-services {
  padding-top: 120px;
  padding-bottom: 140px;

  .service-title {
    text-align: left;
    margin-bottom: 40px;
  }

  a:hover {
    text-decoration: none !important;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 32px;
    width: 100%;
    margin: 0 auto;

    .services-list__item {
      .services-card {
        width: 100%;
        height: 100%;
        padding: 30px 21px;

        @include border-animation-container(
          //
          $animation-light-color,
          $animation-light-color,
          $animation-dark-color,
          //
          $animation-dark-color,
          $animation-light-color,
          $animation-light-color,
          //
          $animation-dark-color,
          $animation-light-color,
          $animation-light-color,
          //
          $animation-light-color,
          $animation-light-color,
          $animation-dark-color,
          //
          $animation-border-size,
          $borderSimple: true
        );

        @include border-animation-reverse($animation-time, $animation-border-size);

        &:hover {
          @include border-animation($animation-time, $animation-border-size);

          background-color: $new-extra-dark-blue;

          & :nth-child(n) {
            color: $secondaryColor !important;
          }
        }
      }
    }
  }
}

@media (max-width: 1386px) {
  $staff-top: 200px;

  .service__header {
    &--staff {
      .container {
        .service-title {
          &::before {
            top: $staff-top;
          }

          &::after {
            top: $staff-top;
          }
        }
      }
    }
  }
}

@media (max-width: 1350px) {
  $software-top: 72px;
  $management-top: 83px;

  .service__header {
    // lines
    &--software {
      .container {
        .service-title {
          &::before {
            top: $software-top;
          }

          &::after {
            top: $software-top;
          }
        }
      }
    }

    &--management,
    &--mobile,
    &--web,
    &--consulting,
    &--uiux,
    &--staff,
    &--qa {
      .container {
        .service-title {
          &::before {
            top: $management-top;
          }

          &::after {
            top: $management-top;
          }
        }
      }
    }

    // letters
    &--software {
      .letter-T {
        &::before {
          height: 60px;
        }
      }
    }
  }
}

@media (max-width: 1244px) {
  $staff-top: 160px;

  .service__header {
    &--staff {
      .container {
        .service-title {
          &::before {
            top: $staff-top;
          }

          &::after {
            top: $staff-top;
          }
        }
      }
    }
  }
}

@media (max-width: 1210px) {
  $software-top: 65px;
  $staff-top: 80px;

  .service__header {
    &--software {
      .container {
        .service-title {
          &::before {
            top: $software-top;
          }

          &::after {
            top: $software-top;
          }
        }
      }
    }

    &--staff,
    &--qa {
      .container {
        .service-title {
          &::before {
            top: $staff-top;
          }

          &::after {
            top: $staff-top;
          }
        }
      }
    }

    &--uiux {
      .letter-I {
        &::before {
          height: 65px;
        }
      }
    }
  }
}

@media (max-width: 1033px) {
  $staff-top: 150px;

  .service__header {
    &--staff {
      .container {
        .service-title {
          &::before {
            top: $staff-top;
          }

          &::after {
            top: $staff-top;
          }
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .service__details {
    margin-top: 52px;
    margin-bottom: 0;

    .container {
      .services__grid {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-gap: 20px;

        &.staff {
          grid-template-rows: repeat(1, 1fr);
        }

        .services__item {
          &-card {
            padding: 32px !important;
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  $management-top: 66px;

  .service__header {
    // lines
    &--management {
      .container {
        .service-title {
          &::before {
            top: $management-top;
          }

          &::after {
            top: $management-top;
          }
        }
      }
    }

    // letters
    &--management {
      .letter-T {
        &::before {
          height: 60px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  $software-top: 220px;
  $mobile-top: 150px;

  .service__header {
    // lines
    &--software {
      .container {
        .service-title {
          &::before {
            top: $software-top;
          }

          &::after {
            top: $software-top;
          }
        }
      }
    }

    &--mobile,
    &--web {
      .container {
        .service-title {
          &::before {
            top: $mobile-top;
          }

          &::after {
            top: $mobile-top;
          }
        }
      }
    }

    // letters
    &--software {
      .container {
        .service-title {
          &.desktop {
            display: none;
          }

          &.mobile {
            display: unset;

            .word-T {
              .letter-T {
                &::before {
                  height: 75px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  $line-height: 6px;
  $software-top: 150px;
  $management-top: 105px;
  $mobile-top: 55px;

  .service__header {
    // lines
    &--software {
      .container {
        .service-title {
          &::before {
            height: $line-height;
            top: $software-top;
          }

          &::after {
            height: $line-height;
            top: $software-top;
          }
        }
      }
    }

    &--management,
    &--staff {
      .container {
        .service-title {
          &::before {
            height: $line-height;
            top: $management-top;
          }

          &::after {
            height: $line-height;
            top: $management-top;
          }
        }
      }
    }

    &--mobile,
    &--web,
    &--consulting,
    &--uiux,
    &--qa {
      .container {
        .service-title {
          &::before {
            height: $line-height;
            top: $mobile-top;
          }

          &::after {
            height: $line-height;
            top: $mobile-top;
          }
        }
      }
    }

    // letters
    &--uiux {
      .letter-I {
        &::before {
          height: 40px;
        }
      }
    }

    &--consulting,
    &--mobile,
    &--web,
    &--qa,
    &--staff {
      .service-title {
        font-size: 38px;
      }
      .letter-T {
        &::before {
          width: $line-height;
          height: 50px;
          top: 10px;
        }

        &::after {
          height: $line-height;
          top: 10px;
          left: -2px;
        }
      }
    }

    &--staff {
      .letter-T {
        &::before {
          left: 10px;
        }

        &::after {
          left: 0;
        }
      }
    }

    &--software,
    &--management {
      .container {
        .service-title {
          &.desktop {
            display: none;
          }
          &.mobile {
            display: unset;
            .word-T {
              .letter-T {
                &::before {
                  width: $line-height;
                  height: 50px;
                  top: 10px;
                }
                &::after {
                  height: $line-height;
                  top: 10px;
                  left: -2px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .service__details {
    .container {
      .services__grid {
        display: flex;
        flex-direction: column;

        &.staff {
          grid-template-rows: repeat(1, 1fr);
        }

        .services__item {
          &-card {
            padding: 32px !important;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .other-services {
    padding-top: 50px;
    padding-bottom: 100px;

    &__list {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(4, 1fr);
    }
  }
}

@media (max-width: 575px) {
  $software-top: 105px;
  $management-top: 55px;
  $staff-top: 55px;

  .service__header {
    &--software {
      .container {
        .service-title {
          &::before {
            top: $software-top;
          }

          &::after {
            top: $software-top;
          }
        }
      }
    }

    &--management {
      .container {
        .service-title {
          &::before {
            top: $management-top;
          }

          &::after {
            top: $management-top;
          }
        }
      }
    }

    &--staff {
      .container {
        .service-title {
          &::before {
            top: $staff-top;
          }

          &::after {
            top: $staff-top;
          }
        }
      }
    }
  }
}

@media (max-width: 572px) {
  $staff-top: 55px;

  .service__header {
    &--staff {
      .container {
        .service-title {
          &::before {
            top: $staff-top;
          }

          &::after {
            top: $staff-top;
          }
        }
      }
    }
  }
}

@media (max-width: 559px) {
  $management-top: 105px;

  .service__header {
    &--management,
    &--staff {
      .container {
        .service-title {
          &::before {
            top: $management-top;
          }

          &::after {
            top: $management-top;
          }
        }
      }
    }
  }
}

@media (max-width: 549px) {
  $software-top: 150px;

  .service__header {
    &--software {
      .container {
        .service-title {
          &::before {
            top: $software-top;
          }

          &::after {
            top: $software-top;
          }
        }
      }
    }
  }
}

@media (max-width: 519px) {
  $line-height: 6px;
  $mobile-top: 55px;

  .service__header {
    &--mobile {
      .container {
        .service-title {
          &::before {
            height: $line-height;
            top: $mobile-top;
          }

          &::after {
            height: $line-height;
            top: $mobile-top;
          }
        }
      }
    }
  }
}

@media (max-width: 507px) {
  $mobile-top: 105px;

  .service__header {
    &--mobile {
      .container {
        .service-title {
          &::before {
            top: $mobile-top;
          }

          &::after {
            top: $mobile-top;
          }
        }
      }
    }
  }
}

@media (max-width: 455px) {
  $line-height: 6px;
  $web-top: 55px;

  .service__header {
    &--web {
      .container {
        .service-title {
          &::before {
            height: $line-height;
            top: $web-top;
          }

          &::after {
            height: $line-height;
            top: $web-top;
          }
        }
      }
    }
  }
}

@media (max-width: 444px) {
  $web-top: 105px;

  .service__header {
    &--web {
      .container {
        .service-title {
          &::before {
            top: $web-top;
          }

          &::after {
            top: $web-top;
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .other-services {
    padding-top: 50px;
    padding-bottom: 100px;

    &__list {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }
}

@media (max-width: 381px) {
  $line-height: 6px;
  $qa-top: 105px;

  .service__header {
    // line
    &--qa {
      .container {
        .service-title {
          &::before {
            height: $line-height;
            top: $qa-top;
          }

          &::after {
            height: $line-height;
            top: $qa-top;
          }
        }
      }
    }

    // letter
    &--qa {
      .letter-T {
        &::before {
          height: 100px;
        }
      }
    }
  }
}

@media (max-width: 359px) {
  .service__header {
    margin-bottom: 0;

    .container {
      position: relative;

      .service-title {
        &.mobile {
          font-size: 31px;

          .word-T {
            .letter-T {
              &::after {
                display: none;
              }
              &::before {
                display: none;
              }

              .hidden-T {
                color: #50cbe9;
                opacity: 1;
              }
            }
          }
        }
      }
      .service-title {
        font-size: 31px;

        .word-T {
          .letter-T {
            &::after {
              display: none;
            }
            &::before {
              display: none;
            }

            .hidden-T {
              color: #50cbe9;
              opacity: 1;
            }
          }
        }

        &::before {
          display: none;
        }
        &::after {
          top: 45px;
        }
      }
    }
  }
}

@media (max-width: 295px) {
  .service__header {
    .container {
      position: relative;

      .service-title {
        font-size: 29px;
      }
    }
  }
}
