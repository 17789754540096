// Main
$black: #071b2b;
$lightgray: #cdd1d5;
$blue: #0019ff;
$skyblue: #82c6f4;
$lightblue: #ecf7ff;

$primaryColor: #f3f8fa;
$textColor: #e1f1ff;
$secondaryColor: #50c5ea;
$trinaryColor: #2a69b4;
$new-dark-blue: #011730;
$new-extra-dark-blue: #01142b;
// Functional
$red: #e33c3c;
$red05: rgba(227, 60, 60, 0.05);
// Additional
$blueHover: #3d51ff;
$white: #fff;
$white10: rgba(255, 255, 255, 0.1);
$white20: rgba(255, 255, 255, 0.2);
$white50: rgba(255, 255, 255, 0.5);
// Basic Gradients
$defaultGradient: linear-gradient(90deg, #2a69b4 0%, #82c6f4 100%);
$errorGradient: linear-gradient(90deg, #8c2626 0%, #e33c3c 100%);
// Services Gradients
$software: linear-gradient(75deg, #269ef4 0%, #b316eb 105%);
$staff: linear-gradient(75deg, #87ff93 0%, #217a7a 105%);
$consulting: linear-gradient(75deg, #26f491 0%, #1651eb 105%);
$mobile: linear-gradient(75deg, #ff6464 0%, #462bb0 105%);
$web: linear-gradient(75deg, #32d2c9 0%, #7255e5 105%);
$uiux: linear-gradient(75deg, #ffa800 0%, #f42e5d 105%);
$qa: linear-gradient(75deg, #64c0c6 0%, #00ffd1 105%);
$management: linear-gradient(75deg, #32c9d2 0%, #11063d 105%);
// Social
$facebook: #306199;
$linkedin: #0077b7;
$instagram: #e83c5b;
// Projects
$beElegant: #30383d;
