// Bootstrap functions
@import '../../bootstrap/scss/functions';
// Bootstrap variables and mixins
@import '../../bootstrap/scss/variables';
@import '../../bootstrap/scss/mixins';
// Bootstrap components
@import '../../bootstrap/scss/reboot';
@import '../../bootstrap/scss/grid';
@import '../../bootstrap/scss/type';
@import '../../bootstrap/scss/utilities';
@import '../../bootstrap/scss/pagination';

// Custom components (Atoms, Molecules, Components, Pages)
// Atoms
@import '_colors';
@import '_fonts';
@import '_variables';
@import '_input';
// Molecules
@import '_commons';
@import '_btn';
@import '_nav';
@import './common/form';
@import '_contact';
@import '_social';
// Components
@import './common/input';
@import './common/our-team-info';
@import './letters/letters';
@import './layout/header';
@import './layout/footer';
@import './common/__promo';
@import './common/__promo-info';
@import './common/_header-background';
@import './cookies';
// Pages
@import './not-found/not-found';
@import './home/home';
@import './our-team/our-team';
@import './ai/ai';
@import './services/services';
@import './case-studies/case-studies';
@import './careers/careers';
@import './contact-us/contact-us';
@import './gdpr/gdpr';

.section {
  &__description {
    margin-bottom: 0;
    color: $black;
    max-width: 660px;
  }
}

.reviews {
  padding-top: calc(var(--basicDistance) / 2);
  margin-bottom: var(--basicDistance);

  .review {
    display: grid;

    &__rate,
    &__source {
      align-self: center;
      justify-self: center;
    }

    &__rate {
      margin-bottom: 12px;
    }

    &__source {
      margin-bottom: 24px;
      .clutch-logo {
        height: 18px;
      }
    }

    &__author {
      align-self: center;
      & > p {
        margin-bottom: 0;
      }
    }

    &__text {
      margin-bottom: calc(var(--basicDistance) / 2);

      .quote-icon {
        margin-bottom: 24px;
        height: 28px;
      }

      .heading {
        margin-bottom: 0;
      }
    }
  }

  .swiper {
    &-container {
      padding-bottom: 80px;
      &-horizontal {
        & > .swiper-pagination-bullets {
          .swiper-pagination-bullet {
            margin: 0 6px;
          }
        }
      }
    }

    &-pagination {
      bottom: 0;
      &-bullet {
        background: $skyblue;
        width: 12px;
        height: 12px;
        &-active {
          background: $blue;
        }
      }
    }
  }
}

.page-content {
  padding-top: calc(var(--basicDistance) / 2);
  margin-bottom: var(--basicDistance);
}

@include media-breakpoint-up(md) {
  .main {
    padding-top: 130px;
    padding-bottom: 30px;
    .title,
    .description {
      color: $white;
      max-width: 660px;
    }
    .title {
      margin-bottom: 24px;
    }
    .description {
      font-size: 1em;
    }
    .container {
      position: relative;
      height: 100%;
    }
    &__services {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 112px;
      justify-content: space-evenly;
      padding: 20px 0;
      & > span {
        font-size: 1.2em;
        margin-bottom: 0;
        &:after {
          transition: $basicTransition;
          transform: translateX(-10px);
          opacity: 0;
        }
        &:hover {
          &:after {
            transform: translateX(0);
            opacity: 1;
          }
        }
      }
    }
  }
  .reviews {
    padding-top: calc(var(--basicDistance) / 2);
    margin-bottom: var(--basicDistance);
    &__container {
      border-right: 2px solid $lightblue;
      border-left: 2px solid $lightblue;
      padding: calc(var(--basicDistance) / 2) 0;
    }
    &__controls {
      padding: 0;
      position: relative;
    }
    .review {
      display: grid;
      grid-template-columns: 25% 1fr;
      column-gap: 30px;
      &__rate,
      &__source {
        align-self: center;
        justify-self: center;
        margin-bottom: 0;
      }
      &__source {
        order: 3;
        .clutch-logo {
          height: 24px;
        }
      }
      &__author {
        align-self: center;
        order: 4;
        & > p {
          margin-bottom: 0;
        }
      }
      &__text {
        margin-bottom: calc(var(--basicDistance) / 2);
        padding-right: calc(var(--basicDistance) / 2);
        .quote-icon {
          margin-bottom: 40px;
          height: 38px;
        }
      }
    }
    .swiper {
      &-container {
        padding-bottom: 0;
      }
      &-pagination {
        display: none;
      }
      &-button {
        &-prev,
        &-next {
          width: 60px;
          height: 100px;
          background-position-y: center;
          background-repeat: no-repeat;
          left: 0;
          right: 0;
          top: 0;
          width: 100%;
          height: 100%;
          margin: 0;
          transition: background 0.16s ease-out;
          &:after {
            display: none;
          }
        }
        &-prev {
          background-image: url(/public/img/icon-controls-prev.svg);
          background-position-x: 50%;
          &:hover {
            background-position-x: 30%;
          }
        }
        &-next {
          background-image: url(/public/img/icon-controls-next.svg);
          background-position-x: 50%;
          &:hover {
            background-position-x: 70%;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .main {
    &__services {
      & > span {
        flex-basis: unset;
      }
    }
  }
}
