body {
  background: $basicThemeColor;
  font-family: 'Inter', sans-serif;
}

.wrapper {
  overflow: hidden;
}

.title,
.heading,
b {
  font-family: 'Inter', sans-serif;
  color: $black;
}

.title {
  font-size: 1.8em;
}

.subtitle {
  font-family: 'Inter', sans-serif;
  color: $black;
  font-size: 1.2em;
  margin-bottom: 12px;
}

.heading {
  font-size: 1.7em;
  margin-bottom: 16px;
  line-height: 1.4em;
  &--level-2 {
    font-size: 1.5em;
  }
  &--level-3 {
    font-size: 1.3em;
    margin-bottom: 24px;
  }
}

.social {
  &__icon {
    & > a {
      text-decoration: none;
      padding: 0 2px;
      display: block;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.document-part {
  padding-top: calc(var(--basicDistance) / 2);
  margin-bottom: var(--basicDistance);
  .text-section {
    margin-bottom: calc(var(--basicDistance) / 2);
    ul {
      li {
        margin-bottom: 8px;
      }
      &.list-letters {
        list-style-type: lower-latin;
        li {
          padding-left: 8px;
        }
      }
    }
  }
  a {
    color: $blue;
  }
}

@include media-breakpoint-up(sm) {
}

@include media-breakpoint-up(md) {
  .wrapper {
    padding-top: 0;
  }

  .title {
    font-size: 2.8em;
  }

  .heading {
    font-size: 2.2em;
    margin-bottom: 16px;
    &--level-2 {
      font-size: 1.6em;
    }
    &--level-3 {
      font-size: 1.3em;
      margin-bottom: 24px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .container {
    max-width: 1440px;
    width: calc(100% - 60px);
  }

  .title {
    font-size: 3.4em;
  }

  .heading {
    font-size: 2.8em;
    margin-bottom: 16px;
    &--level-2 {
      font-size: 1.7em;
    }
    &--level-3 {
      font-size: 1.3em;
      margin-bottom: 24px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .container {
    width: calc(100% - 180px);
  }
}
