@import '../colors';

#btnNav {
  width: 24px;
  height: 21px;
  margin-right: 16px;
  position: relative;
  outline: none;
  border: none;
  background: none;
  overflow: hidden;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#btnNav span {
  display: block;
  position: absolute;
  width: 24px;
  height: 3px;

  background: $secondaryColor;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#btnNav span:nth-child(1) {
  top: 0px;
}

#btnNav span:nth-child(2),
#btnNav span:nth-child(3) {
  top: 9px;
}

#btnNav span:nth-child(4) {
  top: 18px;
}

#btnNav.open span:nth-child(1) {
  top: 9px;
  width: 0%;
  left: 50%;
}

#btnNav.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#btnNav.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#btnNav.open span:nth-child(4) {
  top: 9px;
  width: 0%;
  left: 50%;
}
