.header__background-page {
  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -10;

    &__img {
      background-image: url(/public/img/services-bg.png);
      background-position: center top -80px;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &__scene {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      transform: scaleX(1.1);
    }
  }
}

@media (max-width: 1100px) {
  .header__background-page {
    &__background {
      &__img {
        background-size: unset;
        background-position: left -37px top -50px;
      }
    }
  }
}

@media (max-width: 767px) {
  .header__background-page {
    padding-top: 92px;

    &__background {
      &__img {
        background-size: unset;
        background-position: left -370px top -25px;
      }
    }
  }
}

.christmas-tree {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;

  &__title {
    width: 430px;
    height: 28px;

    &::before {
      position: absolute;
      right: -20px;
      top: 50px;
      content: 'Merry Christmas';
      width: 100%;
      z-index: -1;
      background-color: #00b01c;
    }
  }

  @media (max-width: 767px) {
    display: none;
  }
}
