@import './filter-modal';

.case-studies-page__filters {
  padding-top: 70px;
  padding-bottom: 80px;
  display: flex;
  flex-wrap: wrap;
  gap: 22px;

  &.tablet {
    display: none;
  }

  .filter-plank {
    display: flex;
    justify-content: space-between;

    &-details {
      display: flex;
      align-items: center;
      gap: 19px;
    }

    &-title {
      color: #50c5ea;
      font-size: 12px;
      font-weight: 500;
      line-height: 26px;
    }
  }

  .filter-item {
    position: relative;

    width: max-content;
    height: 40px;
    padding: 10px 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border: 1px solid transparent;
    border-radius: 24px;

    background: rgba(255, 255, 255, 0.05);
    cursor: pointer;

    &:hover {
      background: linear-gradient(#011730, #011730) padding-box,
        linear-gradient(to right bottom, #fff, rgba(255, 255, 255, 0.01), #fff)
          border-box;
    }

    &.active {
      background: linear-gradient(#011730, #011730) padding-box,
        linear-gradient(
            to right top,
            #50c5ea 0%,
            rgba(80, 197, 234, 0.01) 7%,
            #50c5ea 50%,
            #50c5ea 75%,
            rgba(80, 197, 234, 0.01) 93%,
            #50c5ea 100%
          )
          border-box;
    }
  }
}

@media (max-width: 767px) {
  .case-studies-page__filters {
    padding-top: 15px;
    padding-bottom: 35px;

    &.desktop {
      display: none;
    }

    &.tablet {
      display: block;
    }
  }
}
