@import './careers-form';
@import './job';

$animation-time: 0.25s;
$animation-border-size: 1px;
$animation-light-color: #50c5ea;
$animation-dark-color: #01142b;

$margin-left: 66px;
$margin-increment: 105px;

.careers-section {
  position: relative;
  padding-bottom: 141px;

  .page-content {
    padding-top: 130px;
    margin-bottom: 0;
  }

  &__title {
    &::before {
      position: absolute;
      content: '';
      top: 230px;
      left: 0;
      height: 10px;
      width: 100vw;
      background: linear-gradient(
        90deg,
        #6baac5 -8.33%,
        #2990b1 71.22%,
        #50cbe9 100%
      );
    }

    .title-letter-e {
      position: relative;
      color: #4f9fbd;

      &::before {
        position: absolute;
        content: '';
        top: 57px;
        left: 4.999999px;
        height: 50px;
        width: 12px;
        background: #4f9fbd;

        @media (max-width: 1350px) {
          left: 4.3px;
          height: 31px;
        }
        @media (max-width: 1210px) {
          height: 30px;
        }
        @media (max-width: 767px) {
          top: 36px;
          left: 2.2px;
          width: 7px;
          height: 15px;
        }
        @media (max-width: 350px) {
          display: none;
        }
      }

      .christmas-gift {
        position: absolute;
        left: 0px;
        top: -31px;

        @media (max-width: 1350px) {
          left: -3px;
          top: -35px;
        }

        @media (max-width: 1210px) {
          left: -5px;
        }

        @media (max-width: 767px) {
          left: -15px;
          top: -30px;
          transform: scale(0.5);
        }
      }
    }
  }

  &__description {
    margin-top: 17px;

    max-width: 660px;
  }

  .careers__position {
    &-card {
      height: 100%;
      justify-content: flex-start;
      border: none;
      cursor: pointer;

      @include border-animation-container(
        //
        $animation-light-color,
        $animation-light-color,
        $animation-dark-color,
        //
        $animation-dark-color,
        $animation-light-color,
        $animation-light-color,
        //
        $animation-dark-color,
        $animation-light-color,
        $animation-light-color,
        //
        $animation-light-color,
        $animation-light-color,
        $animation-dark-color,
        //
        $animation-border-size,
        $borderSimple: true
      );

      @include border-animation-reverse($animation-time, $animation-border-size);

      &:hover {
        @include border-animation($animation-time, $animation-border-size);

        background-color: $new-extra-dark-blue;

        & :nth-child(n) {
          color: $secondaryColor !important;
        }
      }

      &.transformed {
        padding-left: 85px;
      }
    }

    &__title {
      display: flex;
    }
  }
}

.careers {
  &__position {
    position: relative;
    width: max-content;
    min-width: 488px;
    height: 88px;

    &-container {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      gap: 30px;

      .carrers__position-rect {
        position: relative;

        &::before {
          position: absolute;
          content: '';
          top: 11px;
          right: 5px;
          height: 10px;
          width: 100vw;
          background: linear-gradient(270deg, #50c5ea 15.66%, #50e0e4 94.88%);
        }
      }
    }

    a {
      text-decoration: none;
    }

    &__title {
      padding: 0.2em 0px;
      margin: 0 12px 0 0;
    }

    .badge {
      display: inline-block;
      position: relative;
      height: 24px;
      overflow: hidden;
      margin-bottom: -4px;

      &--hot,
      &--new {
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 56px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        align-self: stretch;

        .badge__text {
          padding: 0 7px;
          transform: rotate(-90deg);
          font-family: 'Inter-SemiBold', sans-serif;
          font-size: 24px;
          font-weight: 600;
          letter-spacing: 2.4px;
          text-transform: uppercase;
        }
      }

      &--new {
        background: #50c5ea;

        .badge__text {
          color: #f3f8fa;
        }
      }

      &--hot {
        background: #f3f8fa;

        .badge__text {
          color: #011730;
        }
      }
    }
  }

  &__apply {
    margin-bottom: calc(200px - var(--basicDistance));

    .heading {
      margin-bottom: calc(var(--basicDistance) / 2);
    }
  }
}

@include media-breakpoint-up(md) {
  .careers {
    &__list {
      margin-top: 20px;
    }

    &__position {
      &-container {
        margin-bottom: 56px;

        &:nth-child(1) {
          margin-left: 60px;
        }

        &:nth-child(2) {
          margin-left: 165px;
        }

        &:nth-child(3) {
          margin-left: 270px;
        }

        &:nth-child(4) {
          margin-left: 375px;
        }

        &:nth-child(5) {
          margin-left: 480px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      &__title {
        font-size: 1.8em;
      }

      .badge {
        margin-bottom: unset;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .careers {
    &__list {
      margin-top: 135px;
    }

    &__position {
      &__title {
        font-size: 2.25em;
        background-size: 0% 5px;
        &:hover {
          cursor: pointer;
          background-size: 100% 5px;
        }
      }
    }
  }
}

@media (max-width: 1350px) {
  .careers-section {
    &__title {
      &::before {
        top: 210px;
      }
    }
  }
}

@media (max-width: 1210px) {
  .careers-section {
    &__title {
      &::before {
        top: 200px;
      }
    }
  }
}

@media (max-width: 1090px) {
  .careers-section {
    .careers__row {
      justify-content: center;
    }

    .careers__list {
      .careers__position-container {
        margin-left: 0;
      }
    }
  }
}

@media (max-width: 767px) {
  .careers-section {
    padding-top: 40px;
    padding-bottom: 52px;

    &__title {
      &::before {
        top: 216px;
      }
    }

    .careers__row {
      padding-top: 52px;
      padding-bottom: 52px;
      justify-content: center;
    }

    .careers__list {
      .careers__position-container {
        .carrers__position-rect {
          display: none;
        }

        .careers__position {
          min-width: 100%;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .careers-section {
    &__title {
      &::before {
        height: 6px;
      }
    }
  }
}
