.contact {
  &__list {
    & > div {
      margin-bottom: 12px;
    }
  }
  &__link {
    color: $white;
    transition: $basicTransition;
    &:hover {
      opacity: 0.4;
      text-decoration: none;
      color: $white;
    }
  }
  &__aside {
    padding: 20px;
    background-color: $black;
    color: $white;
    .contact__list {
      margin-bottom: 40px;
    }
  }
  &__profiles {
    margin-bottom: 60px;
  }
  &__local-time {
    margin-bottom: 40px;
    .separator {
      display: inline-block;
      vertical-align: middle;
      width: 6px;
      height: 6px;
      background: $white;
      border-radius: 100%;
      margin: 0 12px 2px;
      animation: blinking 1s linear infinite alternate;
    }
  }
}

@keyframes blinking {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.profile {
  margin-bottom: 24px;

  &__photo {
    width: 56px;
    min-width: 56px;
    height: 56px;
    position: relative;
    margin-right: 16px;
    & > img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
  }

  &__badge {
    position: absolute;
    width: 16px;
    height: 16px;
    bottom: 0;
    right: 0;
    background: url('/public/img/linkedin/linkedin-badge.svg');
  }

  &__fullname {
    font-family: 'Inter', sans-serif;
  }

  &__position {
    font-size: 0.875em;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  &__details {
    transition: $basicTransition;
  }

  &:hover {
    .profile {
      &__details {
        opacity: 0.6;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .contact {
    &__aside {
      min-width: 340px;
      padding: 40px;
    }
  }
}
