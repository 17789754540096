.word-T {
  position: relative;
  white-space: nowrap;

  .letter-T {
    position: relative;

    .hidden-T {
      opacity: 0;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 21px;
      width: 95%;
      height: 10px;
      background-color: #2e93b3;

      @media (max-width: 1210px) {
        top: 17px;
      }
    }

    &::before {
      content: '';
      position: absolute;
      left: calc(50% - 6px);
      top: 21px;
      width: 10px;
      height: 80px;
      z-index: -1;
      background: linear-gradient(
        360deg,
        #2e93b3 10.56%,
        #4feae1 40.44%,
        #6baac5 67.53%,
        #2990b1 91.22%,
        #2e93b3 100%
      );

      @media (max-width: 1210px) {
        top: 17px;
      }
    }
  }
}
