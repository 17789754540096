.home-technology {
  padding: 125px 0 65px 0;
  margin: 0;
  display: grid;
  grid-template-rows: repeat(4, minmax(0, 1fr));
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-auto-flow: column;
  gap: 24px 12px;
}

.technology__item {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 16px;

  border-radius: 1px;
  border: 1px solid;
  border-image: linear-gradient(to right top, #50c5ea00, #50c5ea, #50c5ea00);
  border-image-slice: 1;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
}

.swiper.home-technology-mobile {
  display: none !important;
}

@media (max-width: 991px) {
  .home-technology {
    grid-template-rows: repeat(4, minmax(0, 1fr));
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (max-width: 767px) {
  .home-technology {
    padding: 94px 0;
    grid-auto-flow: unset;
    grid-template-rows: repeat(6, minmax(0, 1fr));
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (max-width: 560px) {
  .container.technology {
    padding: 0;
  }
  .home-technology {
    display: none;
  }

  .swiper.home-technology-mobile {
    display: block !important;
  }

  .home-technology-mobile {
    margin-top: 95px;
    margin-bottom: 95px;
    height: 200px;

    .swiper {
      width: 100%;
      height: 100%;
      margin-left: auto;
      margin-right: auto;

      .swiper-slide {
        text-align: center;
        height: max-content !important;

        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;

        .technology__item {
          width: 156px;
          height: 36px;
          font-size: 12px !important;
        }
      }
    }
  }
}

@media (max-width: 359px) {
  .technology__item {
    padding: 5px;
    gap: 5px;

    img {
      width: 20px;
      height: 20px;
    }
  }
}
