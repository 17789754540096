@import './ai-item';

.ai-page {
  position: relative;
  padding-top: 130px !important;

  &__container.tablet {
    display: none;
  }

  .section__head {
    padding-bottom: 30px;

    display: flex;
    flex-direction: column;
    gap: 24px;

    &-title {
      .letter-I {
        position: relative;
        display: inline-block;
        width: 45px;

        &-circle {
          top: -5px;
          left: 6px;
        }

        .christmas-hat {
          position: absolute;
          left: -9px;
          top: -16px;

          @media (max-width: 767px) {
            transform: scale(0.5);
            left: -14px;
            top: -13px;
          }
        }

        .hidden-I {
          opacity: 0;
        }

        // gorizontal line
        &::after {
          content: '';
          position: absolute;
          left: 17.5px;
          bottom: 0;
          width: 100vw;
          height: 10px;
          background: linear-gradient(
            270deg,
            #67b4c9 -1.56%,
            #4feae1 54.3%,
            #6baac5 100%
          );
        }

        // vertical line
        &::before {
          content: '';
          position: absolute;
          left: calc(50% - 5px);
          top: 21px;
          width: 10px;
          height: 80px;
          z-index: -1;
          background: linear-gradient(
            0deg,
            #6baac5 -8.33%,
            #2990b1 71.22%,
            #50cbe9 100%
          );
        }
      }
    }
  }

  &__container {
    position: relative;

    &::after {
      position: absolute;
      content: '';
      bottom: 70px;
      height: 10px;
      width: 100vw;
      z-index: 1;
      background: linear-gradient(
        270deg,
        #4fc5ea -1.56%,
        #4feae1 20.44%,
        #6baac5 47.53%,
        #2990b1 71.22%,
        #2a40b6 100%
      );

      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  &__items {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    @media screen and (min-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
    }

    .ai-page__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 55px;

      .projects__item-page {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
      }

      .ai-page-card {
        min-height: max-content;
        padding: 16px 16px 30px 16px;

        @include border-animation-container(
          //
          $animation-light-color,
          $animation-light-color,
          $animation-dark-color,
          //
          $animation-dark-color,
          $animation-light-color,
          $animation-light-color,
          //
          $animation-dark-color,
          $animation-light-color,
          $animation-light-color,
          //
          $animation-light-color,
          $animation-light-color,
          $animation-dark-color,
          //
          $animation-border-size,
          $borderSimple: true
        );

        @include border-animation-reverse($animation-time, $animation-border-size);

        &:hover {
          @include border-animation($animation-time, $animation-border-size);

          background-color: $new-extra-dark-blue;

          & :nth-child(n) {
            color: $secondaryColor !important;
          }
        }
      }

      .ai-page {
        &__tags {
          width: 100%;
          margin-bottom: 20px;
          display: flex;
          justify-content: space-between;
          gap: 8px;
          flex-wrap: wrap;

          .ai-page__tag {
            padding: 10px 22px;
            border-radius: 24px;
            background: rgba(255, 255, 255, 0.05);

            @media (max-width: 991px) {
              padding: 0;
              background: unset;
              text-align: left !important;
            }
          }

          @media (max-width: 991px) {
            order: 1;
            margin-bottom: 0;
          }
        }

        &__images {
          width: 100%;
          margin-bottom: 40px;

          display: flex;
          align-items: flex-end;
          justify-content: space-evenly;

          @media (max-width: 991px) {
            margin-bottom: 20px;
          }
        }

        &__image {
          max-width: 100%;
        }

        &__description {
          @media (max-width: 991px) {
            display: none;
          }
        }
      }

      .circle-ai-page {
        position: relative;

        &::before {
          position: absolute;
          content: '';
          top: 30px;
          width: 10px;
          height: 48px;
          left: 11px;
          background: $secondaryColor;
        }
      }
    }
  }
}

.hero-img {
  position: relative;
  background-color: $black;
  padding-bottom: 78%;
  & > .img {
    &__title {
      z-index: 5;
    }
    &__app {
      z-index: 10;
    }
    &__title,
    &__app {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      pointer-events: none;
    }
  }
  &.beElegant {
    background-color: $beElegant;
    & > .img {
      &__title {
        max-width: 80%;
      }
      &__app {
        max-height: 80%;
      }
    }
  }
}

.projects {
  &__grid {
    margin-bottom: calc(var(--basicDistance) * 1.5);
  }

  &__item-page {
    display: block;
    margin-bottom: var(--basicDistance);
    cursor: pointer;
    perspective: 1000px;

    &:hover {
      text-decoration: none;
      .projects__hero-img {
        box-shadow: $projectShadow;
      }
    }
  }

  &__hero-img {
    margin-bottom: 32px;
    transform-style: preserve-3d;
    transition: box-shadow 0.12s ease-out, transform 0.1s ease-out;
    will-change: transform;
    .glow-area {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 600px;
      pointer-events: none;
      opacity: 0;
      transition: opacity 0.5s ease-out;
    }
    & > .img {
      &__title,
      &__app {
        transition: 0.5s ease-out;
        transform: translateZ(0);
      }
    }
    &:hover {
      & > .img {
        &__title {
          transform: translateZ(20px);
        }
        &__app {
          transform: translateZ(80px);
        }
      }
      .glow-area {
        opacity: 1;
      }
    }
  }
  &__description {
    .heading {
      margin-bottom: 6px;
    }
    & > * {
      color: $black;
      text-decoration: none;
    }
    & > p {
      font-size: 1.1em;
    }
  }
}

.project {
  margin-bottom: calc(var(--basicDistance) * 1.5);
  &__header {
    margin-bottom: 40px;
  }
  &__overview {
    margin-bottom: 60px;
  }
  .title,
  &__details {
    margin-bottom: 32px;
  }
  &__fill {
    background-color: $black;
    margin-bottom: 40px;
    position: relative;
    &--large {
      padding-bottom: 110%;
    }
    &--full {
      width: calc(100% + var(--wrapperPadding) * 2);
      margin-left: calc(var(--wrapperPadding) * -1);
    }
    &__content {
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;
      .app-screen {
        margin: 0 12px;
        max-height: 76%;
        &--main {
          max-height: 86%;
        }
      }
    }
  }
  &__text {
    margin-bottom: 60px;
  }
  &__aside {
    margin-bottom: 120px;
    &__area {
      margin-bottom: 40px;
      padding-bottom: 100%;
      .project__fill {
        position: absolute;
        width: calc(100% + 15px);
        height: 100%;
        &__content {
          width: calc(100% + 15px);
          .app-screen {
            margin: 0 4px;
            &--large {
              max-height: 84%;
            }
            &--medium {
              max-height: 76%;
            }
            &--small {
              max-height: 68%;
            }
          }
        }
      }
      &--left {
        .project__fill {
          left: -15px;
          &__content {
            padding-right: 4px;
          }
        }
      }
      &--right {
        .project__fill {
          right: -15px;
          &__content {
            left: -15px;
            padding-left: 4px;
          }
        }
      }
    }
  }
  &__app-screens {
    padding-bottom: 120%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 320%;
  }
  &__web-screens {
    padding-top: 70px;
    margin-top: -70px;
    overflow: hidden;
    margin-bottom: 120px;
    .screens--row-1 {
      position: relative;
    }
    .screens--shifted {
      margin-left: 150px;
    }
    .web-screen {
      margin: 0 8px 16px;
      height: 180px;
      &--main {
        position: relative;
        z-index: 1;
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 5%;
          width: 90%;
          height: 100%;
          box-shadow: 0px 42px 86px rgba(32, 34, 35, 0.25);
          background: white;
          z-index: -1;
        }
        & > img {
          filter: drop-shadow(0px 2px 24px rgba(32, 34, 35, 0.05));
          height: 100%;
        }
      }
    }
  }
  .store-badge {
    margin: 40px 30px 0;
  }
  &.beElegant {
    .project {
      &__fill {
        background-color: $beElegant;
        &__content {
          .app-screen {
            filter: $beElegantDropShadow;
          }
        }
      }
    }
  }
  p {
    font-size: 1.1em;
  }
}
.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 2;
}
.z-3 {
  z-index: 3;
}

@include media-breakpoint-up(sm) {
  .project {
    &__aside {
      &__area {
        .project__fill {
          width: 100%;
          &__content {
            width: 100%;
            overflow: visible;
          }
        }
        &--left {
          .project__fill {
            left: 0;
            &__content {
              padding-right: unset;
            }
          }
        }
        &--right {
          .project__fill {
            right: unset;
            left: 0;
            &__content {
              left: unset;
              padding-left: unset;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .hero-img {
    margin-bottom: 32px;
    padding-bottom: 42%;
  }
  .projects {
    &__grid {
      margin-bottom: 200px;
    }
    &__hero-img {
      margin-bottom: 32px;
      padding-bottom: 42%;
    }
  }
  .project {
    margin-bottom: 200px;
    &__header {
      margin-bottom: 80px;
    }
    &__overview {
      margin-bottom: 200px;
    }
    &__fill {
      margin-bottom: 80px;
      &--large {
        padding-bottom: 44%;
      }
      &__content {
        .app-screen {
          margin: 0 28px;
          max-height: 80%;
          &--main {
            max-height: 90%;
          }
        }
      }
    }
    &__text {
      margin-bottom: var(--basicDistance);
      &--aside {
        padding-top: 60px;
      }
    }
    &__aside {
      min-height: 420px;
      margin-bottom: 120px;
      &__area {
        height: 420px;
        position: absolute;
        padding: 0;
        .project__fill {
          position: absolute;
          width: calc(100% - 15px);
          height: 100%;
          &__content {
            overflow: hidden;
            width: calc(100% + 40px);
          }
        }
        &--left {
          left: 0;
          .project__fill__content {
            right: -40px;
            padding-right: 16px;
          }
        }
        &--right {
          right: 0;
          .project__fill {
            right: 0;
            left: unset;
            &__content {
              left: -40px;
              padding-left: 16px;
            }
          }
        }
      }
    }
    &__app-screens {
      padding-bottom: 52%;
      background-size: auto;
      background-attachment: fixed;
      height: 600px;
    }
    &__web-screens {
      .web-screen {
        height: auto;
      }
    }
    .store-badge {
      margin: 60px 30px 0;
    }
  }
}

@include media-breakpoint-up(lg) {
  .project {
    &__aside {
      min-height: 520px;
      &__area {
        height: 520px;
        .project__fill {
          &__content {
            .app-screen {
              margin: 0 8px;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .project {
    &__aside {
      min-height: 580px;
      &__area {
        height: 580px;
      }
    }
  }
}

@media (max-width: 1350px) {
  .ai-page {
    .section__head {
      &-title {
        .letter-I {
          // gorizontal line
          &::after {
            bottom: -15px;
          }

          // vertical line
          &::before {
            height: 65px;
          }
        }
      }
    }
  }
}

@media (max-width: 1210px) {
  .ai-page {
    .section__head {
      &-title {
        .letter-I {
          // vertical line
          &::before {
            height: 60px;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .ai-page {
    position: relative;
    padding-top: 92px !important;

    &::after {
      position: absolute;
      content: '';
      top: 200px;
      height: 6px;
      width: 100vw;
      background: linear-gradient(
        270deg,
        #4fc5ea -1.56%,
        #4feae1 20.44%,
        #6baac5 47.53%,
        #2990b1 71.22%,
        #2a40b6 100%
      );
    }

    .section__head {
      &-title {
        position: relative;

        .letter-I {
          width: 28px;

          &-circle {
            top: -4px;
            left: -4px;
          }

          // gorizontal line
          &::after {
            display: none;
          }
          // vertical line
          &::before {
            top: 18px;
            width: 6px;
            height: 45px;
          }
        }
      }
    }

    &__container.desktop {
      display: none;
    }
    &__container.tablet {
      display: block;
    }

    &__items {
      grid-template-columns: repeat(1, 1fr);
      padding-bottom: 35px;
    }

    &__item {
      align-items: flex-start !important;

      .projects__item-page {
        flex-direction: row !important;
        margin-bottom: 0;

        .circle-ai-page {
          &::before {
            top: 12px;
            height: 6px;
            width: 100vw;
          }
        }
      }

      &:nth-child(odd) {
        align-items: flex-end !important;

        .projects__item-page {
          .ai-page-card {
            order: 1;
          }

          .circle-ai-page {
            &::before {
              display: none;
            }
            &::after {
              position: absolute;
              content: '';
              right: 10px;
              top: 12px;
              height: 6px;
              width: 100vw;
              z-index: -1;
              background: linear-gradient(
                270deg,
                #4fc5ea -1.56%,
                #4feae1 20.44%,
                #6baac5 47.53%,
                #2990b1 71.22%,
                #2a40b6 100%
              );
            }
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .ai-page {
    .ai-page-card {
      padding: 12px !important;
      width: 242px;
    }
  }
}

@media (max-width: 359px) {
  .ai-page .section__head-title {
    font-size: 28px;
  }
  .ai-page__items .ai-page__item .circle-ai-page {
    display: none;
  }
}
