.our-team {
  position: relative;
  padding-bottom: 120px;

  @media (max-width: 767px) {
    padding-bottom: 52px;
  }

  &__title {
    padding-top: 130px;
    padding-bottom: 130px;

    @media (max-width: 575px) {
      padding-top: 80px;
      padding-bottom: 52px;
    }

    .container {
      &::before {
        @media (max-width: 767px) {
          position: absolute;
          content: '';
          left: 0;
          top: 177px;
          height: 6px;
          width: 100vw;
          z-index: 1;
          background: linear-gradient(
            270deg,
            #4fc5ea -1.56%,
            #4feae1 20.44%,
            #6baac5 47.53%,
            #2990b1 71.22%,
            #2a40b6 100%
          );
        }

        @media (max-width: 575px) {
          top: 126px;
        }
      }
    }

    &-header {
      .word-T {
        position: relative;

        &::before {
          position: absolute;
          content: '';
          bottom: 0;
          left: 24.5px;
          height: 10px;
          width: 100vw;
          background: linear-gradient(
            90deg,
            #6baac5 -8.33%,
            #2990b1 71.22%,
            #50cbe9 100%
          );

          @media (max-width: 1350px) {
            left: 21.5px;
          }
          @media (max-width: 1210px) {
            left: 18.5px;
          }
          @media (max-width: 767px) {
            display: none;
          }
        }

        .letter-T {
          @media (max-width: 1350px) {
            &::before {
              height: 65px;
            }
            &::after {
              top: 18px;
            }
          }
          @media (max-width: 1210px) {
            &::before {
              height: 68px;
            }
            &::after {
              top: 17px;
            }
          }
          @media (max-width: 767px) {
            &::before {
              width: 6px;
              height: 32px;
              left: 10.5px;
              top: 16px;
            }
            &::after {
              top: 10px;
              height: 6px;
            }
          }
        }
      }
    }

    &-description {
      margin-top: 17px;

      max-width: 640px;
    }
  }

  &__photos {
    padding-bottom: 120px;

    @media (max-width: 767px) {
      padding-bottom: 52px;
    }

    &-mobile {
      @media (min-width: 768px) {
        display: none;
      }

      .swiper-controls {
        margin: 32px 0;
        display: flex;
        justify-content: center;
        gap: 16px;

        & > * {
          width: 48px;
          height: 48px;
        }
      }
    }

    .our-team-img {
      width: 100%;
      height: 100%;
    }

    &-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(328px, 1fr));
      gap: 32px;

      img {
        width: 100%;

        @media (max-width: 767px) {
          display: none;
        }
      }

      @media (max-width: 500px) {
        gap: 24px;
      }
    }

    &-card {
      @media (max-width: 767px) {
        padding: 32px !important;
      }
    }
  }

  &__values {
    position: relative;

    &::before {
      position: absolute;
      content: '';
      top: 178px;
      left: 0;
      height: 10px;
      width: 100vw;
      z-index: 1;
      background: linear-gradient(
        270deg,
        #4fc5ea -1.56%,
        #4feae1 20.44%,
        #6baac5 47.53%,
        #2990b1 71.22%,
        #2a40b6 100%
      );

      @media (max-width: 1100px) {
        top: 163px;
      }

      @media (max-width: 991px) {
        display: none;
      }
    }

    &-grid {
      display: flex;
      gap: 32px;

      @media (max-width: 991px) {
        gap: 10px;
      }
      @media (max-width: 767px) {
        flex-direction: column;
      }
    }

    &-card {
      width: 33%;
      padding: 24px 32px !important;
      justify-content: flex-start !important;
      gap: 40px !important;

      @media (max-width: 991px) {
        gap: 16px !important;
        padding: 24px 10px !important;
      }

      @media (max-width: 767px) {
        width: 100%;
        padding: 16px !important;
      }
    }

    &-header {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 15px;

      .values-number {
        color: var(--new-light-blue, #50c5ea);
        font-family: 'Inter-SemiBold', sans-serif;
        font-size: 43px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }

  .container-fluid {
    max-width: 1440px;
    padding: 0;

    @media (max-width: 767px) {
      padding: 0 16px;
    }
  }

  &__leaders {
    padding-top: 120px;

    @media (max-width: 767px) {
      padding-top: 52px;
    }

    &-leadership {
      &-container {
        padding-bottom: 80px;

        @media (max-width: 767px) {
          padding-bottom: 32px;
        }

        .swiper-slide-prev,
        .swiper-slide-next {
          .leader-item-card {
            position: relative;

            &::after {
              position: absolute;
              content: '';
              top: 0;
              right: 0;
              width: 100%;
              height: 110%;
              opacity: 0.25;
              background: rgba(0, 0, 0, 1);
              border: 2px solid;
            }
          }
        }

        .leader-item-card {
          max-width: 870px;
          max-height: 352px;

          &__photo {
            max-width: 256px;
            max-height: 256px;
            display: flex;
            align-items: center;
            overflow: hidden;

            &-block {
              width: 100%;
              display: flex;
              justify-content: center;

              @media (max-width: 767px) {
                max-width: 200px;
                max-height: 200px;
                display: flex;
                align-items: center;
                overflow: hidden;
              }

              @media (max-width: 500px) {
                max-width: 80px;
                max-height: 80px;
              }
            }

            &-item {
              height: 256px;

              @media (max-width: 500px) {
                height: unset;
                width: 100%;
              }

              &__bigger {
                width: 256px;
                height: unset;

                @media (max-width: 767px) {
                  width: 65%;
                }
                @media (max-width: 537px) {
                  width: 55%;
                }
                @media (max-width: 500px) {
                  width: 100%;
                }
              }
            }

            .leader-fullname-position-mobile {
              display: none;

              @media (max-width: 767px) {
                display: block;
              }
            }

            @media (max-width: 767px) {
              align-items: flex-start;
              gap: 16px;
              order: 1;
              max-width: 100%;
            }
          }

          &__description {
            display: flex;
            align-items: flex-start;
            gap: 24px;
            flex: 2;

            &-info {
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              &__text {
                @media (max-width: 1315px) {
                  font-size: 17px;
                }
                @media (max-width: 1210px) {
                  font-size: 15px;
                }
              }

              .leader-fullname-position {
                @media (max-width: 767px) {
                  display: none;
                }
              }
            }

            @media (max-width: 767px) {
              flex-direction: column;
              gap: 16px;
            }
          }

          @media (max-width: 767px) {
            padding: 24px 16px;
            flex-direction: column;
            gap: 16px;
          }
          @media (max-width: 500px) {
            max-height: max-content;
          }
        }
      }

      .swiper-controls {
        display: flex;
        justify-content: center;
        gap: 32px;

        @media (max-width: 767px) {
          gap: 16px;

          & > * {
            width: 48px;
            height: 48px;
          }
        }
      }
    }
  }
}

@media (max-width: 359px) {
  .our-team__photos-grid {
    display: flex;
    flex-direction: column;
  }
}
