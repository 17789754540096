// desktop
.services-container {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 100vw;
    height: 10px;

    background: #50c5ea;
  }

  .services-directions {
    padding: 57px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 75px;

    .services-directions__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;

      &::before {
        position: absolute;
        content: '';
        top: 0;
        width: 10px;
        height: 60px;
        background: $secondaryColor;
      }
    }
  }
}

@media (max-width: 767px) {
  .services-container {
    &::before {
      display: none;
    }

    .services-directions {
      flex-direction: column;
      padding-top: 0;
      gap: 48px;

      .services-directions__item {
        flex-direction: row;
        gap: 18px;

        &::before {
          display: none;
        }

        .circle-services {
          position: relative;
          margin-left: 20px;

          &::before {
            position: absolute;
            content: '';
            top: calc(50% - 3px);
            right: 20px;
            width: 100vw;
            height: 6px;
            background: linear-gradient(270deg, #4fc5ea -1.56%, #2a40b6 22%);
          }
        }
      }
    }
  }
}
