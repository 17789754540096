$width-to-modal-column: 940px;

.promo-section {
  padding-top: 64px;
  display: flex;
  gap: 50px;
  align-items: center;

  &-container {
    position: relative;
    padding: 86px 104px 86px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 56px;

    &::after {
      position: absolute;
      content: '';
      width: 100vw;
      height: 100%;
      right: 0;
      z-index: -1;

      background: rgba(255, 255, 255, 0.05);
      border: 1px solid;
      border-radius: 1px;
      border-image: linear-gradient(to right, #50c5ea00, #50c5ea);
      border-image-slice: 1;
    }
  }

  &-rect {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: calc(50% - 5px);
      left: 5px;
      width: 100vw;
      height: 10px;

      background: linear-gradient(
        90deg,
        #4fc5ea -1.56%,
        #4feae1 20.44%,
        #6baac5 47.53%,
        #2990b1 71.22%,
        #2a40b6 100%
      );
    }
  }

  &-modal {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    &__title {
      @media (max-width: $width-to-modal-column) {
        max-width: 250px !important;
        text-align: left !important;
        font-size: 20px !important;
      }
    }

    .content-form__title-mobile {
      display: none;

      @media (max-width: $width-to-modal-column) {
        display: block;
      }
    }

    &__content {
      display: flex;
      align-items: center;
      height: 100%;

      &-form {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .content-form__title {
          margin-bottom: 20px;

          @media (max-width: $width-to-modal-column) {
            display: none;
          }
        }

        .form-input__label {
          text-align: left;

          @media (max-width: $width-to-modal-column) {
            font-size: 20px !important;
          }
        }

        .text-tiny {
          text-align: left;
        }

        @media (max-width: $width-to-modal-column) {
          width: 100%;
          order: 1;
        }
      }

      .form--contact {
        margin-bottom: 0;

        .form__fields {
          margin-bottom: 24px;
          gap: 24px;
        }

        .form-input__button {
          margin: 0 auto;
        }
      }

      &-image {
        width: 50%;

        &-mobile {
          display: none;
          margin: 0 auto;
          overflow: hidden;

          .promo-pdf-preview-mobile {
            width: 90%;
            height: auto;

            @media (max-width: 940px) {
              height: 100%;
            }
          }

          @media (max-width: $width-to-modal-column) {
            display: flex;
            align-items: center;
          }
        }

        .promo-pdf-preview {
          width: 70%;
          height: auto;

          &-mobile {
            display: none;
            width: 90%;
            height: auto;

            @media (max-width: $width-to-modal-column) {
              display: block;
            }
          }

          @media (max-width: $width-to-modal-column) {
            display: none;
          }
        }
      }

      @media (max-width: $width-to-modal-column) {
        flex-direction: column;
        height: unset;
      }
    }
  }
}

@media (max-width: 1100px) {
  .promo-section {
    &-container {
      padding-right: 50px;
      gap: 30px;
    }
  }
}

@media (max-width: 1000px) {
  .promo-section {
    &-container {
      padding: 40px 50px 40px 0;
      flex-direction: column;
      justify-content: center;
    }
  }
}

@media (max-width: 767px) {
  .promo-section {
    padding-top: 94px;
    justify-content: center;

    &-container {
      padding: 24px 12px;
      flex-direction: column;
      gap: 45px;

      &::after {
        top: 0;
        right: unset;

        border: 0;
        border-top: 1px solid;
        border-image: linear-gradient(to right, #50c5ea 1%, #50c5ea00 100%);
        border-image-slice: 1;
      }

      &::before {
        position: absolute;
        content: '';
        width: 100vw;
        height: 100%;
        z-index: -1;
        top: 0;

        border: 0;
        border-bottom: 1px solid;
        border-image: linear-gradient(to right, #50c5ea00 1%, #50c5ea 100%);
        border-image-slice: 1;
      }
    }

    &-rect {
      display: none;
    }
  }
}
