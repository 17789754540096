.footer {
  padding-top: 42px;
  background-color: $primaryColor;

  .heading {
    color: $white;
    margin-bottom: 40px;
  }

  &__nav {
    padding: 60px 0;

    ul {
      & > li:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  &__contact {
    padding-top: 60px;

    &__label {
      color: $white50;
      margin-bottom: 12px;
    }
  }

  &__top {
    padding-bottom: 40px;
  }

  .clutch-container {
    margin-bottom: 45px;
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
      margin-bottom: 32px;
      gap: 16px;
    }

    .clutch-container__rating {
      a {
        text-decoration: none;
      }

      &-values {
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        gap: 8px;

        @media (max-width: 767px) {
          margin-bottom: 0;
        }

        .rating-value {
          color: #011730;
          font-family: 'Inter-SemiBold';
          font-size: 32px;
          font-weight: 600;
          line-height: 38px;
          letter-spacing: 0.32px;
          text-transform: uppercase;

          @media (max-width: 767px) {
            font-size: 18px;
            line-height: 20px;
            letter-spacing: 0.17;
          }
        }

        .stars-clutch {
          img {
            width: 24px;

            @media (max-width: 767px) {
              width: 12px;
            }
          }
        }
      }

      .clutch-footer-logo {
        width: 93px;

        @media (max-width: 767px) {
          width: 50px;
        }
      }
    }

    .clutch-container__images {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-link {
        display: inline-block;
        width: 20%;
        text-align: center;

        .link-image {
          width: 80%;
        }
      }
    }
  }

  &__bottom {
    padding-bottom: 28px;
  }

  .separator {
    border-top: 1px solid $trinaryColor;
    margin-top: 0;
    margin-bottom: 28px;
  }

  .copyright {
    font-size: 1em;
    color: $white50;
    text-align: center;
    margin-bottom: 24px;
  }
  .rules {
    margin: 0 0 12px 0;
    & > li {
      margin-bottom: 16px;
    }
    &__link {
      color: $white;
      transition: $basicTransition;
      &:hover {
        opacity: 0.4;
        color: $white;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .footer {
    &__nav {
      padding: 0;
    }
    &__contact {
      padding: 0;
    }
    .copyright {
      text-align: left;
      margin-bottom: 0;
    }
    .rules {
      margin: 0;
      & > li {
        margin-bottom: 0;
      }
      & > li:not(:last-child) {
        margin-right: 36px;
      }
    }
    .social {
      padding-top: 24px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .footer {
    .social {
      padding-top: 0;
    }
  }
}

@media (max-width: 767px) {
  .footer {
    padding: 24px 16px 0 16px;

    &__nav {
      padding: 32px 0 0 0;
    }

    &__top {
      padding-bottom: 32px;

      .intouch-mobile {
        width: 100%;
      }
    }

    &__bottom {
      padding-bottom: 0;

      .contact__mobile {
        margin-bottom: 8px;
      }
    }

    .separator {
      margin-top: 32px;
      margin-bottom: 16px;
    }
  }
}
