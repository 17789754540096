.btn {
  font-family: 'Inter', sans-serif;
  color: $white;
  font-size: 1em;
  border: none;
  border-radius: $basicRadius;
  transition: $basicTransition;
  outline: none;
  height: 56px;
  width: 100%;
  &:focus {
    outline: none;
  }
  &:disabled {
    background-color: $lightgray;
  }
  &__primary {
    background-color: $blue;
    &:not(:disabled):hover {
      background-color: $blueHover;
    }
  }
  &__secondary {
    background-color: $lightblue;
    color: $blue;
    &:hover {
      color: $white;
      background-color: $blue;
    }
  }
  &__footer {
    background-color: $white10;
    &:hover {
      background-color: $white20;
    }
  }
  @include media-breakpoint-up(md) {
    width: auto;
  }
}
