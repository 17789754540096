@font-face {
  font-family: "Inter-Regular";
  src: url("../font/Inter-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Inter-Medium";
  src: url("../font/Inter-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Inter-SemiBold";
  src: url("../font/Inter-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Inter-ExtraBold";
  src: url("../font/Inter-ExtraBold.ttf");
  font-weight: 800;
}
