.not-found {
  padding-top: calc(var(--basicDistance) / 2);
  margin-bottom: var(--basicDistance);

  .section__head {
    .not-found-mobile-text {
      display: none;
    }
  }

  .heading {
    padding-top: 96px;

    text-align: left;
    font-family: 'Inter-SemiBold';
    font-size: 80px;
    font-weight: 600;
    line-height: normal;

    background: var(
      --Blue-Linear,
      linear-gradient(
        0deg,
        #4fc5ea 4.71%,
        #4feae1 26.9%,
        #6baac5 54.23%,
        #2990b1 78.13%,
        #2a40b6 107.16%
      )
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__btn {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &__btn.mobile {
    display: none;
  }

  .not-found-wrapper {
    display: flex;
    gap: 72px;

    .circles-404 {
      .circles-desktop {
        padding: 0;
        display: grid;
        grid-template-columns: repeat(13, 1fr);
        grid-template-rows: repeat(15, 1fr);
        gap: 16px;
      }

      .circles-mobile {
        display: none;
      }
    }

    .not-found-button {
      margin-top: 80px;
    }
  }
}

@media (max-width: 1100px) {
  .not-found {
    position: relative;

    .not-found-mobile-bg {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      z-index: -10;

      background-image: url(/public/img/main-bg.png);
      background-position: left top -30px;
      background-repeat: no-repeat;
      transform: scale(1);
    }

    .heading {
      font-size: 40px;
      text-transform: uppercase;
    }

    &-wrapper {
      flex-direction: column;

      .circles-404 {
        order: 1;
      }
    }
  }
}

@media (max-width: 767px) {
  .not-found {
    .section__head {
      .not-found-mobile-text {
        display: block;
        margin-top: 24px;
      }
    }

    &__btn.desktop {
      display: none;
    }

    &__btn.mobile {
      display: inline-block;
      order: 2;
      margin: 0 auto;

      .not-found-button {
        margin: 0;
      }
    }

    &-wrapper {
      flex-direction: column;
      gap: 30px !important;

      .circles-404 {
        order: 1;

        .circles-desktop {
          display: none !important;
        }

        .circles-mobile {
          padding: 0;
          display: grid !important;
          grid-template-columns: repeat(8, 1fr);
          grid-template-rows: repeat(8, 1fr);
          gap: 8px;

          & :nth-child(n) {
            transform: scale(1) !important;

            @media (max-width: 359px) {
              transform: scale(0.5) !important;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .not-found {
    &-wrapper {
      flex-direction: column;

      .circles-404 {
        order: 1;
      }
    }
  }
}

@media (min-width: 450px) {
  .not-found {
    &-wrapper {
      .circles-404 {
        .circles-mobile {
          gap: 5px;
        }
      }
    }
  }
}

@media (max-width: 359px) {
  .not-found {
    &-wrapper {
      .circles-404 {
        .circles-mobile {
          gap: 0px !important;
        }
      }
    }
  }
}
