.form-input {
  display: flex;
  height: 48px;
  padding: 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  color: $primaryColor;

  border-radius: 2px;
  border: none;
  border-bottom: 1px solid #f3f8fa;
  background: rgba(255, 255, 255, 0.05);

  &__label {
    color: #f3f8fa;

    /* New/H4 */
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
  }

  &:focus {
    outline: none;
    border-bottom: 2px solid #50c5ea;

    & + label {
      color: #50c5ea;
    }
  }

  &.has-error {
    border-bottom: 2px solid #f5487c;

    & + label {
      color: #f5487c;
    }
  }
}
