.form {
  &__fields {
    margin-bottom: 57px;

    .input {
      &__group {
        &:not(:last-child) {
          margin-bottom: 24px;
        }
      }
    }

    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: column;
      gap: 40px;

      .input {
        &__group {
          &:not(:last-child) {
            margin-bottom: unset;
          }
        }
      }
    }
  }

  &__success-message {
    position: relative;
    font-family: 'Inter', sans-serif;
    margin-top: 30px;
    font-size: 1.2em;
    background: $lightblue;
    padding: 16px 16px 12px 16px;

    .timeline {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background: $defaultGradient;
      animation: timer-line 10s linear infinite running alternate;
    }
  }

  &__message {
    position: relative;
    font-family: 'Inter', sans-serif;
    margin-top: 30px;
    font-size: 1.2em;
    padding: 18px 18px 14px 18px;

    .timeline {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background: $defaultGradient;
      animation: timer-line 10s linear infinite running alternate;
    }

    &--success {
      background: $lightblue;

      .timeline {
        background: $defaultGradient;
      }
    }

    &--error {
      background: $red05;

      .timeline {
        background: $errorGradient;
      }
    }
  }

  &--contact {
    margin-bottom: 120px;

    @include media-breakpoint-up(md) {
      .input {
        &__group {
          &--textarea {
            grid-column: 2;
            grid-row: 1 / span 3;
            justify-content: flex-end;
          }
        }
      }
    }
  }

  .text-tiny {
    color: var(--new-white-blue, #e1f1ff);

    /* New/Text tiny */
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;

    &__link {
      color: var(--new-white-blue, #e1f1ff);

      /* New/Text tiny */
      color: var(--new-light-blue, #50c5ea);
      text-decoration-line: underline;
    }
  }

  .btn {
    position: relative;
    overflow: hidden;

    &__animation {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      &__background {
        position: absolute;
        width: 200%;
        height: 100%;
        top: 0;
        left: 0;
        background: $defaultGradient;
        z-index: 1;
        animation: runing-background 1.4s linear infinite running alternate;
      }

      &__label {
        position: absolute;
        z-index: 2;
      }
    }

    @media (max-width: 359px) {
      width: 100% !important;
    }
  }

  .btn.form-input__button {
    display: flex;
    width: 312px;
    height: 51px;
    padding: 16px 40px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    border-radius: 2px;
    background: #f3f8fa;

    color: #011730;
    text-align: center;

    /* New/Button */
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.32px;
    text-transform: uppercase;

    &:hover {
      background: #50c5ea;
      color: #f3f8fa;
    }

    &:active {
      background: #2a69b4;
      color: #f3f8fa;
    }

    &:disabled {
      background: #b2b2b2;
      color: #f3f8fa;
    }
  }
}

@keyframes runing-background {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes timer-line {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}
