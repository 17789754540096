@import '../mixins/border-animation';

$animation-time: 0.25s;
$animation-border-size: 1px;
$animation-light-color: #50c5ea;
$animation-dark-color: #01142b;

.case-studies-section {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: -10px;
    right: 0;
    width: 100vw;
    height: 10px;

    background: linear-gradient(
      270deg,
      #4fc5ea -1.56%,
      #4feae1 20.44%,
      #6baac5 47.53%,
      #2990b1 71.22%,
      #2a40b6 100%
    );
  }

  .case-studies-container {
    margin: 0 auto;
    padding-bottom: 145px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;

    .case-studies-items {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 32px;
      align-self: stretch;

      .case-studies-item {
        max-width: 31%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 55px;

        .case-card {
          min-height: max-content;
          padding: 16px 16px 30px 16px;

          @include border-animation-container(
            //
            $animation-light-color,
            $animation-light-color,
            $animation-dark-color,
            //
            $animation-dark-color,
            $animation-light-color,
            $animation-light-color,
            //
            $animation-dark-color,
            $animation-light-color,
            $animation-light-color,
            //
            $animation-light-color,
            $animation-light-color,
            $animation-dark-color,
            //
            $animation-border-size,
            $borderSimple: true
          );

          @include border-animation-reverse($animation-time, $animation-border-size);

          &:hover {
            @include border-animation($animation-time, $animation-border-size);

            background-color: $new-extra-dark-blue;

            & :nth-child(n) {
              color: $secondaryColor !important;
            }
          }
        }

        .circle-case {
          position: relative;

          &::before {
            position: absolute;
            content: '';
            bottom: 30px;
            width: 10px;
            height: 48px;
            left: 11px;
            background: $secondaryColor;
          }

          &::after {
            content: '';
            position: absolute;
            top: -56px;
            left: 11px;
            width: 100vw;
            height: 10px;

            background: linear-gradient(
              90deg,
              #4fc5ea -1.56%,
              #4feae1 30.44%,
              #6baac5 37.53%,
              #2990b1 61.22%,
              #2a40b6 100%
            );
          }
        }

        &:nth-child(1) {
          .circle-case {
            &::before {
              background: linear-gradient(0deg, #2a72b4 15.66%, #50c5ea 94.88%);
            }
          }
        }
        &:nth-child(2) {
          .circle-case {
            &::before {
              background: linear-gradient(0deg, #2a72b4 15.66%, #60c3d0 94.88%);
            }
          }
        }
        &:nth-child(3) {
          .circle-case {
            &::before {
              background: linear-gradient(180deg, #298bb2 15.66%, #50c5ea 94.88%);
            }
          }
        }

        &:nth-child(2),
        &:nth-child(3) {
          .circle-case {
            &::after {
              display: none;
            }
          }
        }

        .case-studies-tags {
          width: 100%;
          margin-bottom: 20px;
          display: flex;
          justify-content: space-between;
          gap: 8px;
          flex-wrap: wrap;

          .case-tag {
            padding: 10px 22px;
            border-radius: 24px;
            background: rgba(255, 255, 255, 0.05);
          }
        }

        .case-studies-images {
          width: 100%;
          margin-bottom: 40px;
          padding-top: 24px;

          display: flex;
          align-items: flex-end;
          justify-content: space-evenly;

          background: $new-dark-blue;

          .case-studies-image {
            flex: 1;
            max-width: 40%;
            height: auto;
          }
        }
      }
    }

    .case-studies-info {
      display: flex;
      gap: 80px;

      .link-to-cases {
        width: max-content;
        text-decoration: none;
      }
    }

    .link-to-cases {
      width: 100%;
      text-decoration: none;
    }

    .case-button-mobile {
      display: none;
    }
  }
}

@media (max-width: 992px) {
  .case-studies-section {
    .case-studies-container {
      .case-studies-items {
        .case-studies-item {
          max-width: 48%;

          &:nth-child(3) {
            display: none;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .case-studies-section {
    &::before {
      height: 6px;
    }

    .case-studies-container {
      padding-bottom: 94px;
      flex-direction: column-reverse;

      .case-studies-items {
        gap: 16px;

        .case-studies-item {
          .circle-case {
            display: none;
          }

          .case-card {
            min-height: 257px;
            padding: 12px 8px;

            .case-card-title {
              font-size: 16px;
            }

            .case-studies-images {
              height: unset;
              margin-bottom: 23px;

              .case-studies-image {
                max-width: 40%;
              }
            }

            .case-studies-tags {
              display: none;
            }
          }

          &:nth-child(3) {
            display: none;
          }
        }
      }

      .case-studies-info {
        .link-to-cases {
          display: none;
        }
        .case-button {
          display: none;
        }
      }

      .case-button-mobile {
        display: block;
        margin: 0 auto;
      }
    }
  }
}

@media (min-width: 380px) {
  .case-studies-section {
    .case-studies-container {
      .case-studies-items {
        .case-studies-item {
          min-height: 275px;
        }
      }
    }
  }
}

@media (min-width: 440px) {
  .case-studies-section {
    .case-studies-container {
      .case-studies-items {
        .case-studies-item {
          min-height: 300px;
        }
      }
    }
  }
}

@media (min-width: 500px) {
  .case-studies-section {
    .case-studies-container {
      .case-studies-items {
        .case-studies-item {
          min-height: 325px;
        }
      }
    }
  }
}

@media (min-width: 560px) {
  .case-studies-section {
    .case-studies-container {
      .case-studies-items {
        .case-studies-item {
          min-height: 325px;
        }
      }
    }
  }
}

@media (max-width: 359px) {
  .case-studies-section {
    .case-studies-container {
      .case-studies-items {
        .case-studies-item {
          max-width: 100%;

          &:nth-child(2),
          &:nth-child(3) {
            display: none;
          }
        }
      }
    }
  }
}

@media (min-width: 500px) {
  .case-studies-description {
    min-height: 120px!important;
  }
}

@media (min-width: 992px) {
  .case-studies-description {
    min-height: 160px!important;
  }
}
@media (min-width: 1200px) {
  .case-studies-description {
    min-height: 130px!important;
  }
}
@media (min-width: 1365px) {
  .case-studies-description {
    min-height: 110px!important;
  }
}
