.letter-I {
  position: relative;
  display: inline-block;
  width: 45px;

  &-circle {
    top: -5px;
    left: 6px;
  }

  .hidden-I {
    opacity: 0;
  }

  // vertical line
  &::before {
    content: '';
    position: absolute;
    left: calc(50% - 5px);
    top: 21px;
    width: 10px;
    height: 80px;
    z-index: -1;
    background: linear-gradient(0deg, #6baac5 -8.33%, #2990b1 71.22%, #50cbe9 100%);
  }
}

@media (max-width: 1350px) {
  .letter-I {
    // vertical line
    &::before {
      height: 65px;
    }
  }
}

@media (max-width: 1210px) {
  .letter-I {
    // vertical line
    &::before {
      height: 60px;
    }
  }
}

@media (max-width: 767px) {
  .letter-I {
    width: 30px;

    &-circle {
      top: -4px;
      left: -3px;
    }

    // vertical line
    &::before {
      top: 18px;
      width: 6px;
      height: 45px;
    }
  }
}
