.cookies {
  position: fixed;
  background-color: $white;
  width: 100%;
  z-index: 100;
  left: 0;
  bottom: 0;
  color: $new-dark-blue;
  box-shadow: 0px -12px 16px 0 rgba(7, 27, 43, 0.15);
  padding: 24px 64px;

  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__title {
    margin-bottom: 8px;

    font-family: 'Inter-SemiBold';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 140% */
    letter-spacing: 0.2px;
    text-transform: uppercase;
  }

  &__text {
    margin-bottom: 12px;

    /* New/Inputs */
    font-family: 'Inter-Medium';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 51px;
    padding: 16px 40px;
    background: $new-dark-blue;

    &:hover {
      border-radius: 2px;
      background: #50c5ea !important;
    }
  }
}

@include media-breakpoint-up(sm) {
  .cookies {
    &__content {
      flex-direction: row;
    }
    &__text {
      margin-bottom: 0;
    }
    &__btn {
      margin-left: 24px;
    }
  }
}

@media (max-width: 575px) {
  .cookies {
    padding: 24px 32px 32px;

    &__btn {
      width: 100%;
    }
  }
}
