.innovations-container {
  margin: 0 auto;
  padding-top: 160px;
  padding-bottom: 160px;

  &__header {
    margin-bottom: 48px;
    text-align: right !important;
  }

  .tabs-container {
    display: flex;

    &-mobile {
      display: none;
    }

    .tabs {
      display: flex;
      flex: 1;
      gap: 24px;
      flex-direction: column;

      .tab {
        height: 80px;
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 16px 40px;
        border: 1px solid;
        border-image: linear-gradient(to right, #50c5ea00, #50c5ea, #50c5ea00);
        border-image-slice: 1;
        cursor: pointer;
        transition: background-color 0.3s;

        &.active {
          background-color: rgba(255, 255, 255, 0.05);
          border: 1px solid;
          border-image: linear-gradient(to right, #50c5ea, #50c5ea00);
          border-image-slice: 1;
        }
      }
    }

    .tab-content {
      position: relative;
      padding: 32px 64px;
      flex: 1;
      background-color: rgba(255, 255, 255, 0.05);
      border: 1px solid;
      border-image: linear-gradient(to left, #50c5ea, #50c5ea00);
      border-image-slice: 1;

      .content-image-container {
        position: absolute;
        bottom: 30px;
        right: 64px;
      }
    }
  }
}

@media (max-width: 1055px) {
  .innovations-container {
    .tabs-container {
      .tab-content {
        padding: 16px 32px;
      }
    }
  }
}

@media (max-width: 1000px) {
  .innovations-container {
    .tabs-container {
      .tabs {
        .tab {
          padding: 10px;
        }
      }

      .tab-content {
        padding: 16px 32px;
      }
    }
  }
}

@media (max-width: 991px) {
  .innovations-container {
    .tabs-container {
      .tabs {
        .tab {
          padding: 10px;
        }
      }

      .tab-content {
        padding: 16px 32px;
      }
    }
  }
}

@media (max-width: 767px) {
  .innovations-container {
    padding-top: 94px;
    padding-bottom: 94px;

    &__header {
      margin-bottom: 36px;
      text-align: left !important;
    }

    .tabs-container {
      display: none;

      &-mobile {
        display: block;

        &-wrapper {
          margin-bottom: 24px;

          .innovation-mobile-card {
            height: 410px;

            .mobile-innovations-header {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 10px;

              .content-image-container {
                img {
                  width: 48px;
                  height: 48px;
                }
              }
            }

            .mobile-innovations-text {
              height: 100%;

              &:nth-child(2) {
                height: 100%;
                display: flex;
                align-items: center;

                @media (max-width: 359px) {
                  align-items: flex-start;
                  overflow: scroll;
                }
              }
            }
          }
        }

        .swiper-controls {
          display: flex;
          justify-content: center;
          gap: 16px;

          & > * {
            width: 48px;
            height: 48px;
          }
        }
      }
    }
  }
}

@media (max-width: 359px) {
  .innovation-mobile-card {
    padding: 10px !important;
  }
}
