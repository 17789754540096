:root {
  --basicDistance: 80px;
  --wrapperPadding: 15px;
  @media (min-width: 768px) {
    --wrapperPadding: 40px;
    --basicDistance: 120px;
  }
  @media (min-width: 992px) {
    --wrapperPadding: 60px;
  }
}
$basicRadius: 6px;
$basicTransition: 0.12s ease-out;
$basicDistance: 80px;
// Projects
$projectShadow: 0px 16px 30px rgba(7, 27, 43, 0.25),
  0 0 12px rgba(7, 27, 43, 0.15);
$beElegantDropShadow: drop-shadow(0px 16px 12px rgba(32, 34, 35, 0.35));

// Colors
$basicThemeColor: #011730;
