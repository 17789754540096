.gdpr-section {
  position: relative;
  padding-top: 0;

  &::before {
    position: absolute;
    content: '';
    top: 227px;
    left: 0;
    height: 10px;
    width: 100vw;
    background: linear-gradient(
      270deg,
      #6baac5 -8.33%,
      #2990b1 71.22%,
      #50cbe9 100%
    );

    @media (max-width: 1350px) {
      height: 8px;
      top: 202px;
    }

    @media (max-width: 1210px) {
      top: 194px;
    }

    @media (max-width: 991px) {
      top: 264px;
    }

    @media (max-width: 767px) {
      height: 6px;
      top: 125px;
    }

    @media (max-width: 439px) {
      top: 173px;
    }
  }

  .section__head {
    padding-top: 128px;
    padding-bottom: 72px;

    @media (max-width: 767px) {
      padding-top: 80px;
      padding-bottom: 51px;
    }
  }
}

.gdpr-title {
  margin-bottom: 32px;

  @media (max-width: 767px) {
    margin-bottom: 16px;
  }
}

.gdpr-highlighted,
.gdpr-link {
  color: #50c5ea !important;
}

.gdpr-highlighted {
  text-transform: uppercase;
}

.text-section__title-first {
  display: flex;
  align-items: center;
  margin-bottom: 32px;

  @media (max-width: 767px) {
    margin-bottom: 16px;
  }

  .gdpr-title {
    width: 60%;
    margin-bottom: 0;
  }

  .circle-gdpr {
    position: relative;

    &::before {
      position: absolute;
      content: '';
      top: 10px;
      left: 5px;
      height: 10px;
      width: 100vw;
      background: linear-gradient(270deg, #2e92b3 0.04%, #4fc5ea 99.96%);
    }

    @media (max-width: 767px) {
      display: none;
    }
  }
}
