@mixin noLine {
  @media (max-width: 991px) {
    &.no-line-small-screen {
      &::before {
        width: 0;
        height: 0;
      }
    }
  }
}

.section-ai-item {
  position: relative;
  padding-top: 130px;

  @media (max-width: 767px) {
    padding-top: 100px;
  }

  &__title {
    position: relative;
    margin-bottom: 17px;

    &::after {
      position: absolute;
      content: '';
      height: 10px;
      width: 100vw;
      background: linear-gradient(
        270deg,
        #6baac5 -8.33%,
        #2990b1 71.22%,
        #50cbe9 100%
      );

      @media (max-width: 767px) {
        height: 6px;
      }
    }

    .ai-title-letter {
      color: #4f9fbd;

      &.t-part,
      &.l-part,
      &.e-part {
        position: relative;

        &::before {
          position: absolute;
          content: '';
          top: 57px;
          height: 50px;
          width: 12px;
          background: #4f9fbd;

          @media (max-width: 350px) {
            display: none;
          }
        }
      }

      &.t-part {
        &::before {
          left: 22px;
          width: 15.5px;

          @media (max-width: 1350px) {
            top: 36px;
            left: 19.5px;
            width: 13.5px;
          }
          @media (max-width: 1210px) {
            left: 18px;
            width: 12px;
          }
          @media (max-width: 767px) {
            left: 9.95px;
            width: 7px;
            height: 14px;
          }
        }

        @include noLine;
      }
      &.l-part {
        &::before {
          left: 5px;

          @media (max-width: 1350px) {
            left: 4.3px;
            height: 29px;
          }
          @media (max-width: 767px) {
            top: 36px;
            left: 2px;
            width: 7px;
            height: 14px;
          }
        }

        @include noLine;
      }
      &.e-part {
        &::before {
          left: 5px;

          @media (max-width: 1350px) {
            left: 4.3px;
            height: 29px;
          }
          @media (max-width: 767px) {
            top: 36px;
            left: 2.2px;
            width: 7px;
            height: 14px;
          }
        }

        @include noLine;
      }
    }
  }

  &__description {
    margin-bottom: 104px;
    display: flex;
    justify-content: flex-start;
    max-width: 640px;

    @media (max-width: 767px) {
      margin-bottom: 65px;
    }
  }

  &__preview {
    margin-bottom: 80px;
    height: 544px;

    @media (max-width: 767px) {
      margin-bottom: 65px;
      height: unset;
    }

    &-card {
      padding: 0 !important;
      gap: 80px !important;

      @media (max-width: 767px) {
        gap: 10px !important;
        height: unset !important;
        width: 100% !important;
      }
    }

    &-image {
      height: 100%;

      @media (max-width: 767px) {
        height: 305px;
        width: auto;
      }
    }
  }

  &__technology {
    margin-bottom: 160px;
    display: flex;
    justify-content: space-between;
    gap: 32px;

    @media (max-width: 767px) {
      flex-direction: column;
      margin-bottom: 65px;
    }

    .platfom,
    .industry,
    .duration {
      width: 33%;
      display: flex;
      flex-direction: column;
      gap: 20px;

      @media (max-width: 767px) {
        width: 50%;
        margin: 0 auto;
      }

      @media (max-width: 560px) {
        width: 75%;
      }

      @media (max-width: 400px) {
        width: 100%;
      }
    }
  }

  &__content {
    margin-bottom: 160px;
    display: flex;
    align-items: center;
    gap: 153px;

    &:nth-child(8) {
      margin-bottom: 100px;
    }

    @media (max-width: 991px) {
      gap: 50px;
    }

    @media (max-width: 767px) {
      margin-bottom: 65px;

      &:nth-child(8) {
        margin-bottom: 0;
      }
    }

    &-description {
      max-width: 50%;
      display: flex;
      flex-direction: column;
      gap: 32px;

      @media (max-width: 991px) {
        max-width: 100%;
      }
    }

    &-circle {
      position: relative;

      &::before {
        position: absolute;
        content: '';
        top: 10px;
        left: 5px;
        height: 10px;
        width: 100vw;
        background: linear-gradient(270deg, #2e92b3 0.04%, #4fc5ea 99.96%);
      }

      @media (max-width: 767px) {
        display: none;
      }
    }

    &.reverse {
      justify-content: flex-end;

      .section-ai-item__content {
        &-description {
          order: 1;
        }

        &-circle {
          &::before {
            display: none;
          }

          &::after {
            position: absolute;
            content: '';
            top: 10px;
            right: 5px;
            height: 10px;
            width: 100vw;
            z-index: -1;
            background: linear-gradient(270deg, #2e92b3 0.04%, #4fc5ea 99.96%);
          }
        }
      }
    }
  }

  &__promo {
    margin-bottom: 160px;

    @media (max-width: 767px) {
      margin-bottom: 65px;
    }
  }
}

@media (max-width: 359px) {
  .section-ai-item__preview-image {
    height: unset;
    width: 100%;
  }
}
