$main-title-line-bottom: -6px;

// desktop
.main {
  padding-top: 100px;
  position: relative;
  overflow: hidden;
  isolation: isolate;

  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -10;

    &__img {
      background-image: url(/public/img/main-bg.png);
      background-position: center top 15px;
      background-size: contain;
      background-repeat: no-repeat;
      transform: scale(1);
    }

    &__scene {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      transform: scaleX(1.1);
    }
  }

  .container {
    position: relative;
    height: 100%;
  }
}

.main-info {
  margin: 0;
  padding: 0 0 140px 0;
  overflow: hidden;

  .word-T {
    white-space: nowrap;

    .letter-T {
      position: relative;

      .hidden-T {
        opacity: 0;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 21px;
        width: 95%;
        height: 10px;
        background-color: #2e93b3;

        @media (max-width: 1210px) {
          top: 17px;
        }
      }

      &::before {
        content: '';
        position: absolute;
        left: calc(50% - 6px);
        top: 21px;
        width: 10px;
        height: 100vh;
        z-index: -1;
        background: linear-gradient(
          360deg,
          #4fc5ea 10.56%,
          #4feae1 40.44%,
          #6baac5 67.53%,
          #2990b1 91.22%,
          #2e93b3 100%
        );

        @media (max-width: 1210px) {
          top: 17px;
        }
      }
    }
  }

  .get-in-touch-button-main {
    display: none;
  }

  .aftertitle {
    max-width: 63%;
  }
}

@media (max-width: 1002px) {
  .main-info {
    .aftertitle {
      max-width: 100%;
    }
  }
}

@media (max-width: 767px) {
  .main {
    &__background {
      &__img {
        background-position: left -65px top 100px;
        background-size: auto;
      }
    }
  }
  .main-info {
    padding: 0 0 94px 0;
    overflow: visible;

    .pretitle {
      margin-bottom: 24px;
    }

    .main-title {
      position: relative;
      padding-bottom: 24px + (-$main-title-line-bottom);
      overflow: hidden;

      .letter-T {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 10px;
          width: 90%;
          height: 6px;
          background-color: #2e93b3;
        }

        &::before {
          content: '';
          position: absolute;
          left: calc(50% - 5px);
          top: 10px;
          width: 6px;
          height: 100vh;
          background: linear-gradient(
            360deg,
            #4fc5ea 10.56%,
            #4feae1 40.44%,
            #6baac5 67.53%,
            #2990b1 91.22%,
            #2e93b3 100%
          );
        }
      }
    }

    .aftertitle {
      position: relative;
      padding-top: 22px;
      margin-bottom: 40px;

      &::before {
        position: absolute;
        content: '';
        width: 100vw;
        height: 6px;
        top: $main-title-line-bottom;
        left: 0;
        background: linear-gradient(
          270deg,
          #4fc5ea 20.1%,
          #4feae1 40.44%,
          #6baac5 47.53%,
          #2990b1 71.22%,
          #2a40b6 100%
        );
      }

      &::after {
        position: absolute;
        content: '';
        width: 100vw;
        height: 6px;
        top: $main-title-line-bottom;
        right: 0;
        background: linear-gradient(
          270deg,
          #4fc5ea -1.56%,
          #4feae1 20.44%,
          #6baac5 47.53%,
          #2990b1 71.22%,
          #2a40b6 100%
        );
      }
    }

    .get-in-touch-button-main {
      display: block;
    }
  }
}
