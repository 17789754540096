@use './burger_animation';

.header {
  height: 56px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  padding: 0 15px;
  background: $new-dark-blue;
  z-index: 100;

  .logo {
    &-icon {
      width: 35px;
      height: 35px;
    }

    &-title {
      height: 17px;
      margin-left: 10px;
    }
  }

  &__nav {
    opacity: 0;
    pointer-events: none;
    background: rgba(255, 255, 255, 0);
    position: fixed;
    width: 100%;
    height: calc(100% - 56px);
    z-index: 100;
    top: 56px;
    left: 0;
    padding: 30px 15px 15px 15px;
    transition: background $basicTransition;

    &.show {
      opacity: 1;
      pointer-events: auto;
      background: $new-dark-blue;

      .header__contact {
        opacity: 1;
      }
      .nav__link {
        transform: translateX(0);
      }
      .get-in-touch-button-mobile {
        opacity: 1;
      }
    }

    &.show > ul {
      @media (max-width: 767px) {
        padding-top: 32px;
      }

      & > li {
        @media (max-width: 767px) {
          margin-bottom: 40px;
        }
      }

      & > li:nth-child(1) {
        .nav__link {
          transition: transform 0.45s ease-out 0.05s;
        }
      }
      & > li:nth-child(2) {
        .nav__link {
          transition: transform 0.45s ease-out 0.15s;
        }
      }
      & > li:nth-child(3) {
        .nav__link {
          transition: transform 0.45s ease-out 0.35s;
        }
      }
      & > li:nth-child(4) {
        .nav__link {
          transition: transform 0.45s ease-out 0.45s;
        }
      }
      & > li:nth-child(5) {
        .nav__link {
          transition: transform 0.45s ease-out 0.55s;
        }
      }
      & > li:nth-child(6) {
        .nav__link {
          transition: transform 0.45s ease-out 0.6s;
        }
      }
      & > li:nth-child(7) {
        .nav__link {
          transition: transform 0.45s ease-out 0.7s;
        }
      }
    }

    .nav__link {
      color: $primaryColor;
      font-size: 1.6em;
      transform: translateX(-100%);
      display: block;
      transition: color $basicTransition;

      &:hover {
        color: $secondaryColor;
        text-decoration: none;
      }

      .ai-icon {
        margin: 0 5px 5px 0;
      }
    }
  }

  &__contact {
    position: absolute;
    bottom: 15px;
    left: 15px;
    width: calc(100% - 30px);
    opacity: 0;
    transition: $basicTransition 0.25s;

    &__link {
      color: $black;
      transition: $basicTransition;
      font-size: 1em;

      &:hover {
        color: $black;
      }
    }

    & > div:not(:last-child) {
      margin-bottom: 8px;
    }

    .social {
      padding-top: 12px;

      &__icon {
        &:not(:last-child) {
          margin-right: 28px;
        }
      }
    }
  }

  .dropdown {
    &__wrapper {
      display: none;
    }
  }

  .get-in-touch-button-mobile {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    opacity: 0;
  }

  .get-in-touch-button-desktop {
    display: none;
  }
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {
}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {
  .header {
    height: 75px;
    padding: 30px 40px 17px 40px;
    position: unset;
    width: unset;
    background: none;
    top: unset;
    left: unset;

    .logo {
      &-icon {
        width: 47px;
        height: 47px;
      }

      &-title {
        height: 24px;
        margin-left: 10px;
      }
    }

    &__nav {
      display: block;
      position: unset;
      width: unset;
      height: unset;
      background: none;
      top: unset;
      left: unset;
      padding: 0;
      opacity: 1;
      pointer-events: auto;

      &.show {
        background: none;
      }

      & > ul {
        padding-top: 0;

        & > li {
          margin-bottom: 0;
          height: 100px;
          display: flex;
          align-items: center;
        }

        & > li:not(:last-child) {
          padding: 0 28px;
        }

        & > :last-child {
          margin-left: 18px;
        }
      }

      .nav__link {
        position: relative;
        display: block;
        overflow: hidden;
        color: transparent;
        font-size: 1em;
        transform: unset;
      }
    }

    .dropdown {
      &__wrapper {
        display: block;
        position: absolute;
        top: 75px;
        left: 0;
        background: $new-dark-blue;
        padding: 24px 80px;
        width: 100%;
        border-top: 1px solid $lightblue;
        border-bottom: 1px solid $lightblue;
        box-shadow: 0px 32px 32px rgba(7, 27, 43, 0.12);
        opacity: 0;
        pointer-events: none;
        transition: 0.24s ease-out;

        &.visible {
          opacity: 1;
          pointer-events: auto;
        }
      }

      &__nav {
        display: flex;
        flex-wrap: wrap;

        li {
          flex-basis: 25%;
          display: flex;
        }
      }

      &__link {
        color: inherit;
        flex: 1;
        padding: 12px 0;
        transition: $basicTransition;

        &:hover {
          color: $secondaryColor;
          text-decoration: none;
        }
      }
    }

    .get-in-touch-button-mobile {
      display: none;
    }
  }

  .logo {
    height: 36px;
  }
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {
}

@media (min-width: 768px) {
  .header {
    padding: 30px 20px 17px 20px;

    &__nav {
      & > ul {
        & > li:not(:last-child) {
          padding: 0 10px;
        }

        & > :last-child {
          margin-left: 10px;
        }
      }
    }

    .logo-title {
      display: none;
    }
  }
}

@media (min-width: 992px) {
  .header {
    padding: 30px 40px 17px 40px;
  }
}

@media (min-width: 1120px) {
  .header {
    &__nav {
      & > ul {
        & > li:not(:last-child) {
          padding: 0 10px;
        }

        & > :last-child {
          margin-left: 10px;
        }
      }
    }

    .logo-title {
      display: inline-block;
    }

    .get-in-touch-button-desktop {
      display: block;
    }
  }
}

@media (min-width: 1215px) {
  .header {
    &__nav {
      & > ul {
        & > li:not(:last-child) {
          padding: 0 20px;
        }

        & > :last-child {
          margin-left: 15px;
        }
      }
    }
  }
}

@media (min-width: 1385px) {
  .header {
    &__nav {
      & > ul {
        & > li:not(:last-child) {
          padding: 0 28px;
        }

        & > :last-child {
          margin-left: 18px;
        }
      }
    }
  }
}
