.clients {
  max-width: 1440px;
  padding: 160px 0;

  &-title {
    display: flex;
    flex-direction: column;

    &__body {
      display: flex;

      #client-rect {
        position: relative;
        height: 100%;

        &::before {
          content: '';
          position: absolute;
          top: calc(50% - 5px);
          left: 5px;
          width: 100vw;
          height: 10px;

          background: linear-gradient(
            90deg,
            #4fc5ea -1.56%,
            #4feae1 10.44%,
            #6baac5 20.53%,
            #2990b1 25.22%,
            #2a40b6 50%
          );
        }
      }
    }

    &__text {
      width: 55%;
    }
  }

  &-reviews {
    &-container {
      padding: 80px 0;

      .swiper-slide-prev,
      .swiper-slide-next {
        .client-item-card {
          position: relative;

          &::after {
            position: absolute;
            content: '';
            top: 0;
            right: 0;
            width: 100%;
            height: 110%;
            opacity: 0.25;
            background: rgba(0, 0, 0, 1);
            border: 2px solid;
          }
        }
      }

      .client-item-card {
        max-width: 870px;
        height: 268px;

        .review-summary {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: space-between;

          .rating {
            display: flex;
            align-items: center;

            .review__source-mobile {
              display: none;
            }
          }
        }

        .review-description {
          display: flex;
          align-items: flex-start;
          gap: 24px;
          flex: 2;

          &__info {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
        }
      }
    }

    .swiper-controls {
      display: flex;
      justify-content: center;
      gap: 32px;
    }
  }
}

@media (max-width: 1100px) {
  .clients {
    &-reviews {
      &-container {
        .client-item-card {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .clients {
    padding: 94px 16px;

    &-title {
      &__body {
        #client-rect {
          display: none;
        }
      }

      &__text {
        width: 100%;
      }
    }

    &-reviews {
      &-container {
        padding: 32px 0;

        .swiper-slide-prev,
        .swiper-slide-next {
          .client-item-card {
            position: relative;

            &::after {
              display: none;
            }
          }
        }

        .client-item-card {
          padding: 24px 16px;
          width: 100%;
          height: max-content;
          flex-direction: column;
          gap: 36px;

          .review-summary {
            order: 1;
            gap: 20px;

            .rating {
              width: 100%;
              order: 1;
              display: flex;

              & > :first-child {
                margin-right: auto;
              }

              .review__source-mobile {
                display: block;
              }
            }

            .review__source {
              display: none;
            }

            .review-details {
              width: 100%;
              gap: 4px;

              &__item {
                display: flex;
                justify-content: space-between;
              }
            }
          }

          .review-description {
            flex-direction: column;
            gap: 16px;

            &__info {
              gap: 16px;
            }
          }
        }
      }

      .swiper-controls {
        gap: 16px;

        & > * {
          width: 48px;
          height: 48px;
        }
      }
    }
  }
}

@media (max-width: 359px) {
  .client-item-card {
    .review-summary {
      .rating {
        .stars {
          img {
            width: 17px;
          }
        }
      }
    }
  }
}
