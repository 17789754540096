.help-block {
  &-info {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 33px;

    .help-rect {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: calc(50% - 5px);
        right: 5px;
        width: 100vw;
        height: 10px;

        background: linear-gradient(
          270deg,
          #4fc5ea -1.56%,
          #4feae1 20.44%,
          #6baac5 47.53%,
          #2990b1 71.22%,
          #2a40b6 100%
        );
      }
    }

    &__text {
      width: 57%;
      display: flex;
      flex-direction: column;
      gap: 28px;
      padding: 38px 30px;
    }
  }

  &-why-we {
    padding-top: 64px;
    display: flex;
    gap: 50px;
    align-items: center;

    .why-we-container {
      position: relative;
      padding: 86px 104px 86px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 56px;

      &::after {
        position: absolute;
        content: '';
        width: 100vw;
        height: 100%;
        right: 0;
        z-index: -1;

        background: rgba(255, 255, 255, 0.05);
        border: 1px solid;
        border-radius: 1px;
        border-image: linear-gradient(to right, #50c5ea00, #50c5ea);
        border-image-slice: 1;
      }
    }

    .why-rect {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: calc(50% - 5px);
        left: 5px;
        width: 100vw;
        height: 10px;

        background: linear-gradient(
          90deg,
          #4fc5ea -1.56%,
          #4feae1 20.44%,
          #6baac5 47.53%,
          #2990b1 71.22%,
          #2a40b6 100%
        );
      }
    }
  }
}

@media (max-width: 1100px) {
  .help-block {
    &-why-we {
      .why-we-container {
        padding-right: 50px;
        gap: 30px;
      }
    }
  }
}

@media (max-width: 1000px) {
  .help-block {
    &-why-we {
      .why-we-container {
        padding: 40px 50px 40px 0;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}

@media (max-width: 767px) {
  .help-block {
    &-info {
      display: block;
      margin: 0 auto;
      width: 100%;

      .help-rect {
        display: none;
      }

      &__text {
        padding: 0;
        margin: 0 auto;
        width: 100%;
      }
    }

    &-why-we {
      padding-top: 94px;
      justify-content: center;

      .why-we-container {
        padding: 24px 12px;
        flex-direction: column;
        gap: 45px;

        &::after {
          top: 0;
          right: unset;

          border: 0;
          border-top: 1px solid;
          border-image: linear-gradient(to right, #50c5ea 1%, #50c5ea00 100%);
          border-image-slice: 1;
        }

        &::before {
          position: absolute;
          content: '';
          width: 100vw;
          height: 100%;
          z-index: -1;
          top: 0;

          border: 0;
          border-bottom: 1px solid;
          border-image: linear-gradient(to right, #50c5ea00 1%, #50c5ea 100%);
          border-image-slice: 1;
        }
      }

      .why-rect {
        display: none;
      }
    }
  }
}
