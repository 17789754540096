.job-page-content {
  position: relative;
  padding-top: 128px;

  @media (max-width: 767px) {
    padding-top: 80px;
  }
}

.job-page-title {
  margin-bottom: 130px;

  @media (max-width: 767px) {
    margin-bottom: 52px;
  }

  @media (max-width: 359px) {
    font-size: 33px !important;
  }
}

.careers__job {
  &-title {
    margin-bottom: 56px;
  }

  &-description {
    margin-bottom: 60px;

    a {
      color: $blue;
      text-decoration: underline;
    }

    ul {
      margin-bottom: 0;

      padding-left: 30px;

      li {
        margin-bottom: 12px;
        color: #e1f1ff;
      }
    }

    .job-base-description,
    .job-offer {
      display: flex;
    }

    .description-circle,
    .offer-circle {
      position: relative;

      ::before {
        position: absolute;
        content: '';
        height: 10px;
        width: 100vw;
        top: 10px;
        left: 5px;
        background: linear-gradient(270deg, #2e92b3 0.04%, #4fc5ea 99.96%);
      }
    }

    .job-description,
    .offer {
      width: 50%;
    }

    .job-description-title {
      margin-bottom: 32px;
      text-align: left;
    }

    .job-base-description {
      gap: 136px;
      margin-bottom: 112px;

      @media (max-width: 767px) {
        margin-bottom: 52px;
      }
    }

    .offer {
      padding-right: 32px;
    }

    .job-requirements {
      margin-bottom: 64px;

      @media (max-width: 767px) {
        margin-bottom: 52px;
      }
    }
  }
}

.job-page-divider {
  width: 100%;
  height: 10px;
  position: relative;

  &::after {
    position: absolute;
    content: '';
    height: 100%;
    width: 100vw;
    background: linear-gradient(
      270deg,
      #2a44b6 15.66%,
      #50d6e6 50.78%,
      #2a44b6 99.94%
    );
  }

  @media (max-width: 767px) {
    display: none;
  }
}

.careers__apply {
  padding-top: 120px;

  @media (max-width: 767px) {
    margin-bottom: 52px !important;
    padding-top: 0;

    .form-input__button {
      margin: 0 auto;
    }
  }
}

@include media-breakpoint-up(md) {
  .careers__job {
    &-description {
      margin-bottom: var(--basicDistance);
    }
  }
}

@media (max-width: 1199px) {
  .careers__job {
    &-description {
      &::after {
        left: -30px;
      }
    }
  }
}

@media (max-width: 1000px) {
  .careers__job {
    &-description {
      .job-description,
      .offer {
        width: 65%;
      }
    }
  }
}

@media (max-width: 9919px) {
  .careers__job {
    &-description {
      &::after {
        left: -136px;
      }
    }
  }
}

@media (max-width: 860px) {
  .careers__job {
    &-description {
      .job-description,
      .offer {
        width: 75%;
      }
    }
  }
}

@media (max-width: 767px) {
  .careers__job {
    &-description {
      .job-description,
      .offer {
        width: 100%;
      }

      .description-circle,
      .offer-circle {
        display: none;
      }

      &::after {
        display: none;
      }
    }
  }
}

@media (max-width: 369px) {
  .careers__job-description ul {
    padding-left: 15px;
  }
}
