@import './service';

$animation-time: 0.25s;
$animation-border-size: 1px;
$animation-light-color: #50c5ea;
$animation-dark-color: #01142b;

.services-page {
  position: relative;
  padding-top: 130px;
  margin-bottom: 160px;

  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -10;

    &__img {
      background-image: url(/public/img/services-bg.png);
      background-position: center top -80px;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &__scene {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      transform: scaleX(1.1);
    }
  }

  .container {
    .section__head {
      padding-bottom: 30px;

      display: flex;
      flex-direction: column;
      gap: 24px;

      &-title {
        .letter-I {
          position: relative;
          display: inline-block;
          width: 45px;

          &-circle {
            top: -5px;
            left: 6px;
          }

          .christmas-hat {
            position: absolute;
            left: -9px;
            top: -16px;

            @media (max-width: 767px) {
              transform: scale(0.5);
              left: -14px;
              top: -13px;
            }
          }

          .hidden-I {
            opacity: 0;
          }

          // horizontal line
          &::after {
            content: '';
            position: absolute;
            left: 17.5px;
            bottom: 0;
            width: 100vw;
            height: 10px;
            background: linear-gradient(
              270deg,
              #67b4c9 -1.56%,
              #4feae1 54.3%,
              #6baac5 100%
            );
          }

          // vertical line
          &::before {
            content: '';
            position: absolute;
            left: calc(50% - 5px);
            top: 21px;
            width: 10px;
            height: 80px;
            z-index: -1;
            background: linear-gradient(
              0deg,
              #6baac5 -8.33%,
              #2990b1 71.22%,
              #50cbe9 100%
            );
          }
        }
      }
    }

    .services__list {
      position: relative;
      padding-top: 90px;
      padding-bottom: 80px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 156px;
      grid-template-rows: repeat(4, minmax(0, 1fr));
      row-gap: 56px;

      &::after {
        position: absolute;
        left: -23px;
        bottom: 0;
        content: '';
        width: 100vw;
        height: 10px;
        background: linear-gradient(
          270deg,
          #2a44b6 15.66%,
          #50d6e6 50.78%,
          #2a44b6 99.94%
        );
        z-index: 2;
      }
    }
  }

  .services-card {
    width: 100%;
    height: 100%;
    padding: 30px 21px;

    @include border-animation-container(
      //
      $animation-light-color,
      $animation-light-color,
      $animation-dark-color,
      //
      $animation-dark-color,
      $animation-light-color,
      $animation-light-color,
      //
      $animation-dark-color,
      $animation-light-color,
      $animation-light-color,
      //
      $animation-light-color,
      $animation-light-color,
      $animation-dark-color,
      //
      $animation-border-size,
      $borderSimple: true
    );

    @include border-animation-reverse($animation-time, $animation-border-size);

    &:hover {
      @include border-animation($animation-time, $animation-border-size);

      background-color: $new-extra-dark-blue;

      & :nth-child(n) {
        color: $secondaryColor !important;
      }
    }
  }

  .services {
    &__tile {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      gap: 40px;

      a {
        width: 100%;
        height: 100%;
        color: inherit;
        text-decoration: none;
      }

      &:nth-child(1),
      &:nth-child(2) {
        .circle-services-list {
          &::after {
            position: absolute;
            content: '';
            top: 10.75px;
            left: -43px;
            height: 555px;
            width: 10px;
            background: linear-gradient(
              180deg,
              #4fc5ea -1.56%,
              #4feae1 20.44%,
              #6baac5 47.53%,
              #2990b1 71.22%,
              #2a40b6 100%
            );
            z-index: 1;
          }
        }
      }
    }
  }

  .circle-services-list {
    position: relative;
    margin-left: 20px;

    &::before {
      position: absolute;
      content: '';
      top: calc(50% - 5px);
      left: -42px;
      width: 43px;
      height: 10px;
      background: linear-gradient(270deg, #50c5ea 15.66%, #50e0e4 94.88%);
    }
  }
}

@media (max-width: 1350px) {
  .services-page {
    .container {
      .section__head {
        &-title {
          .letter-I {
            // gorizontal line
            &::after {
              bottom: -15px;
            }

            // vertical line
            &::before {
              height: 65px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1210px) {
  .services-page {
    .container {
      .section__head {
        &-title {
          .letter-I {
            // vertical line
            &::before {
              height: 60px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .services-page {
    &__background {
      &__img {
        background-size: unset;
        background-position: left -37px top -50px;
      }
    }

    .container {
      .services__list {
        column-gap: 75px;
      }
    }
  }
}

@media (max-width: 1000px) {
  .services-page {
    .container {
      .services__list {
        column-gap: 50px;
        row-gap: 20px;

        .services__tile {
          &:nth-child(1),
          &:nth-child(2) {
            .circle-services-list {
              &::after {
                height: 450px;
              }
            }
          }
        }

        .services-card {
          & :nth-child(n) {
            font-size: 20px !important;
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .services-page {
    .container {
      .services__list {
        .services__tile {
          &:nth-child(1),
          &:nth-child(2) {
            .circle-services-list {
              &::after {
                height: 420px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .services-page {
    .container {
      .services__list {
        .services__tile {
          &:nth-child(1),
          &:nth-child(2) {
            .circle-services-list {
              &::after {
                height: 490px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .services-page {
    padding-top: 92px;

    &__background {
      &__img {
        background-size: unset;
        background-position: left -370px top -25px;
      }
    }

    .container {
      .section__head {
        &-title {
          position: relative;
          .letter-I {
            width: 30px;

            &-circle {
              top: -4px;
              left: -3px;
            }

            // gorizontal line
            &::after {
              display: none;
            }
            // vertical line
            &::before {
              top: 18px;
              width: 6px;
              height: 45px;
            }
          }
        }
      }

      .services__list {
        padding-top: 50px;
        padding-bottom: 50px;
        column-gap: 40px;
        row-gap: 10px;

        .services__tile {
          gap: 5px;

          .circle-services-list {
            margin-left: 20px;

            &::before {
              top: calc(50% - 3px);
              left: -10px;
              width: 20px;
              height: 6px;
            }
          }

          &:nth-child(1),
          &:nth-child(2) {
            .circle-services-list {
              &::after {
                top: 13px;
                left: -15px;
                height: 320px;
                width: 6px;
              }
            }
          }
        }

        &::after {
          left: 5px;
          bottom: 5px;
          width: 100vw;
          height: 6px;
        }

        .services-card {
          padding: 15px 12px;
          justify-content: center;

          & :nth-child(n) {
            font-size: 16px !important;
          }
        }
      }

      &::before {
        content: '';
        position: absolute;
        right: 0;
        top: 150px;
        width: 100vw;
        height: 6px;
        background: linear-gradient(
          270deg,
          #4fc5ea -1.56%,
          #4feae1 20.44%,
          #6baac5 47.53%,
          #2990b1 71.22%,
          #2a40b6 100%
        );
      }
    }
  }
}

@media (max-width: 500px) {
  .services-page {
    .container {
      .services__list {
        .services__tile {
          .circle-services-list {
            display: none;
          }
        }

        &::after {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .services-page {
    .container {
      .services__list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .services-card {
          height: 72px;
          justify-content: flex-start;

          & :nth-child(n) {
            font-size: 20px !important;
          }
        }
      }
    }
  }
}

@media (max-width: 359px) {
  .services-card {
    justify-content: center !important;
  }
}
