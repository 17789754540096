@import '../mixins/border-animation';

$animation-time: 0.25s;
$animation-border-size: 1px;
$animation-white-color: #f3f8fa;
$animation-dark-color: #000000;

.industries-container {
  padding: 144px 28px 0 28px;
  margin: 0 auto;
  max-width: 1440px;

  .section__head {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin-bottom: 60px;
  }

  .industries__grid {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    grid-auto-flow: column;
    place-items: center;
    gap: 24px 32px;
  }

  .industries__grid-mobile {
    display: none;
  }

  .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .industry-item {
    position: relative;
    width: 204px;
    height: 209px;

    @include border-animation-container(
      //
      $animation-white-color,
      $animation-dark-color,
      $animation-white-color,
      //
      $animation-white-color,
      $animation-white-color,
      $animation-white-color,
      //
      $animation-white-color,
      $animation-dark-color,
      $animation-white-color,
      //
      $animation-white-color,
      $animation-white-color,
      $animation-white-color,
      //
      $animation-border-size,
      $borderSimple: false
    );

    @include border-animation-reverse($animation-time, $animation-border-size);

    .industry-icon {
      width: auto;
      height: auto;

      transform-origin: center bottom;
      animation: icon-scale-reverse $animation-time ease forwards;
    }

    .animation-bg {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      padding: 32px 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(255, 255, 255, 0.1);
      opacity: 0;
      transition: opacity $animation-time;
    }

    &:hover {
      @include border-animation($animation-time, $animation-border-size);

      .industry-card-title {
        opacity: 0.1;
      }

      .industry-icon {
        opacity: 0.1;
        transform-origin: center bottom;
        animation: icon-scale $animation-time ease forwards;
      }

      .animation-bg {
        opacity: 1;
      }
    }
  }

  .swiper {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;

    .swiper-slide {
      text-align: center;
      height: max-content !important;

      /* Center slide text vertically */
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media (max-width: 1330px) {
  .industries-container {
    .industries__grid {
      grid-template-columns: repeat(3, 0.1fr);
      grid-template-rows: repeat(2, minmax(0, 1fr));
      justify-content: center;
    }
  }
}

@media (max-width: 992px) {
  .industries-container {
    .industries__grid {
      .industry-item {
        width: 175px;
        height: 175px;
      }
    }
  }
}

@media (max-width: 767px) {
  .industries-container {
    padding: 94px 0 94px;

    .industries__grid {
      .industry-item {
        padding: 12px;
        width: 156px;
        height: 156px;
      }
    }
  }
}

@media (max-width: 600px) {
  .industries-container {
    .industries__grid {
      gap: 10px;
    }
  }
}

@media (max-width: 499px) {
  .industries-container {
    padding: 94px 0 94px;

    .section__head {
      margin-bottom: 34px;
      padding-left: 23px;
      padding-right: 23px;
    }

    .industries__grid {
      display: none;
    }

    .industries__grid-mobile {
      display: block;
      height: 325px;

      .industry-item {
        padding: 12px;
        width: 156px;
        height: 156px;
      }
    }
  }
}

@media (max-width: 359px) {
  .industries-container {
    .industries__grid-mobile {
      .industry-item {
        transform: scale(0.9);
      }
    }
  }
}

$icon-scale: 1.3;

@keyframes icon-scale {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale($icon-scale);
  }
}
@keyframes icon-scale-reverse {
  0% {
    transform: scale($icon-scale);
  }
  100% {
    transform: scale(1);
  }
}
