@mixin border-animation-container(
  $topStartC,
  $topMidC,
  $topEndC,
  $rightStartC,
  $rightMidC,
  $rightEndC,
  $bottomStartC,
  $bottomMidC,
  $bottomEndC,
  $leftStartC,
  $leftMidC,
  $leftEndC,
  $borderSize,
  $borderSimple: true
) {
  background-repeat: no-repeat;

  @if $borderSimple {
    background-image: linear-gradient(to right, $topStartC, $topEndC),
      linear-gradient(to bottom, $rightStartC, $rightEndC),
      linear-gradient(to right, $bottomStartC, $bottomEndC),
      linear-gradient(to bottom, $leftStartC, $leftEndC);
  } @else {
    background-image: linear-gradient(
        to right,
        $topStartC 0%,
        $topMidC 50%,
        $topEndC 100%
      ),
      linear-gradient(
        to bottom,
        $rightStartC 0%,
        $rightMidC 50%,
        $rightEndC 100%
      ),
      linear-gradient(
        to right,
        $bottomStartC 0%,
        $bottomMidC 50%,
        $bottomEndC 100%
      ),
      linear-gradient(to bottom, $leftStartC 0%, $leftMidC 50%, $leftEndC 100%);
  }

  background-size: 100% $borderSize, $borderSize 100%, 100% $borderSize,
    $borderSize 100%;
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
}

@mixin border-animation($time, $borderSize) {
  animation: card-border $time ease 1;

  @keyframes card-border {
    0% {
      background-size: 0 $borderSize, $borderSize 0, 0 $borderSize,
        $borderSize 0;
    }
    25% {
      background-size: 100% $borderSize, $borderSize 0, 0 $borderSize,
        $borderSize 0;
    }
    50% {
      background-size: 100% $borderSize, $borderSize 100%, 0 $borderSize,
        $borderSize 0;
    }
    75% {
      background-size: 100% $borderSize, $borderSize 100%, 100% $borderSize,
        $borderSize 0;
    }
    100% {
      background-size: 100% $borderSize, $borderSize 100%, 100% $borderSize,
        $borderSize 100%;
    }
  }
}

@mixin border-animation-reverse($time, $borderSize) {
  animation: card-border-reverse $time ease 1 forwards;

  @keyframes card-border-reverse {
    0% {
      background-size: 100% $borderSize, $borderSize 100%, 100% $borderSize,
        $borderSize 100%;
    }
    25% {
      background-size: 100% $borderSize, $borderSize 100%, 100% $borderSize,
        $borderSize 0;
    }
    50% {
      background-size: 100% $borderSize, $borderSize 100%, 0 $borderSize,
        $borderSize 0;
    }
    75% {
      background-size: 100% $borderSize, $borderSize 0, 0 $borderSize,
        $borderSize 0;
    }
    100% {
      background-size: 0 $borderSize, $borderSize 0, 0 $borderSize,
        $borderSize 0;
    }
  }
}
