.nav {
  ul {
    margin: 0;
  }
  &__link {
    text-transform: uppercase;
    font-family: 'Inter', sans-serif;
    transition: 0.12s ease-out;
    font-size: 1em;
    &:hover {
      text-decoration: none;
    }
  }
}
