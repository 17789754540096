.our-team-container {
  padding-top: 160px;
  display: flex;
  align-items: center;
  gap: 33px;

  .our-team-rect {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: calc(50% - 5px);
      right: 5px;
      width: 100vw;
      height: 10px;

      background: linear-gradient(
        270deg,
        #4fc5ea -1.56%,
        #4feae1 20.44%,
        #6baac5 47.53%,
        #2990b1 71.22%,
        #2a40b6 100%
      );
    }
  }

  &-info {
    position: relative;
    padding: 40px 0 40px 64px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 88px;

    .our-team__text {
      flex: 0.8;
    }

    .our-team__photo {
      width: 384px;
      height: 276px;
      display: flex;
      justify-content: center;
      border-radius: 1px;

      &-img {
        width: auto;
        height: 100%;
      }
    }

    &::after {
      position: absolute;
      content: '';
      width: 100vw;
      height: 100%;
      left: 0;
      z-index: -1;

      background: rgba(255, 255, 255, 0.05);
      border: 1px solid;
      border-image: linear-gradient(to right, #50c5ea, #50c5ea00);
      border-image-slice: 1;
    }
  }
}

@media (max-width: 1250px) {
  .our-team-container {
    &-info {
      padding: 20px 0 20px 32px;
      gap: 44px;
    }
  }
}

@media (max-width: 1100px) {
  .our-team-container {
    &-info {
      flex-direction: column;
      justify-content: center;
    }
  }
}

@media (max-width: 767px) {
  .our-team-container {
    padding: 0;

    .our-team-rect {
      display: none;
    }

    &-info {
      padding: 24px;
      flex-direction: column;
      gap: 24px;

      .our-team__photo {
        width: 100%;
      }

      &::after {
        width: 100vw;
        height: 100%;
        top: 0;
        left: unset;

        border: 0;
        border-top: 1px solid;
        border-image: linear-gradient(to right, #50c5ea 1%, #50c5ea00 100%);
        border-image-slice: 1;
      }

      &::before {
        position: absolute;
        content: '';
        width: 100vw;
        height: 100%;
        z-index: -1;
        top: 0;

        border: 0;
        border-bottom: 1px solid;
        border-image: linear-gradient(to right, #50c5ea00 1%, #50c5ea 100%);
        border-image-slice: 1;
      }
    }
  }
}

@media (max-width: 420px) {
  .our-team-container-info {
    overflow: hidden;
  }
}

@media (max-width: 359px) {
  .our-team__photo-img {
    width: 140% !important;
  }
}
