.promo__info {
  &-title {
    display: flex;
    flex-direction: column;

    &__body {
      display: flex;
      align-items: center;
      gap: 153px;

      #promo__info-rect {
        flex: 0.75;
        position: relative;
        height: 100%;

        &::before {
          content: '';
          position: absolute;
          top: calc(50% - 5px);
          left: 5px;
          width: 100vw;
          height: 10px;

          background: linear-gradient(
            90deg,
            #4fc5ea -1.56%,
            #4feae1 10.44%,
            #6baac5 20.53%,
            #2990b1 25.22%,
            #2a40b6 50%
          );
        }
      }
    }

    &__text {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 32px;
    }
  }
}

@media (max-width: 1000px) {
  .promo__info {
    &-title {
      &__body {
        gap: 60px;
      }
    }
  }
}

@media (max-width: 767px) {
  .promo__info {
    padding: 94px 16px;

    &-title {
      &__body {
        #promo__info-rect {
          display: none;
        }
      }

      &__text {
        width: 100%;
        gap: 16px;
      }
    }
  }
}
