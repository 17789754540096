.modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;

  opacity: 0;
  animation: fadeIn 0.3s ease-in-out forwards;

  &.closing {
    animation: fadeOut 0.3s ease-in-out forwards;
  }
}

.modal-content {
  border-radius: 2px;
  background: var(--new-dark-blue, #011730);
  padding: 20px;
  width: 320px;
  border-radius: 8px;

  transform: scale(0.8);
  animation: scaleIn 0.3s ease-in-out forwards;

  .filter-modal {
    &-header {
      margin-bottom: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (orientation: landscape) {
        margin-bottom: 0;
      }

      .close {
        font-size: 24px;
        color: #fff;
        cursor: pointer;
      }
    }

    &-items {
      display: flex;
      gap: 12px;
      flex-wrap: wrap;
      margin-bottom: 30px;

      @media (orientation: landscape) {
        margin-bottom: 15px;
      }
    }

    &-buttons {
      display: flex;
      justify-content: space-between;

      @media (max-width: 359px) {
        gap: 10px;
      }
    }
  }

  &.closing {
    animation: scaleOut 0.3s ease-in-out forwards;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes scaleOut {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.8);
  }
}
