.input {
  &__group {
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    justify-content: flex-end;

    & > * {
      display: block;
      transition: 0.12s ease-out;
    }
  }

  &__label {
    color: $lightgray;
    margin-bottom: 6px;
    padding: 0;
    font-size: 0.875em;
  }

  &__field {
    font-family: 'Inter', sans-serif;
    font-size: 1.3125em;
    line-height: 1em;
    color: $black;
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    width: 100%;

    &::placeholder {
      color: $lightgray;
    }

    &:placeholder-shown {
      & + .input__label {
        color: $black;
      }
    }

    &:focus {
      outline: none;
      border: none;
    }
  }

  &__error {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;

    font-size: 0.875em;
    color: $red;
  }
}

.textarea {
  padding-top: 6px;
  line-height: 1.3125em;
  height: 116px;
  resize: none;
}

@include media-breakpoint-up(md) {
  .input {
    &__label {
      font-size: 1em;
    }
    &__field {
      font-size: 1.625em;
    }
  }
  .textarea {
    height: 140px !important;
  }
}
