.contact-us {
  position: relative;

  &__header {
    padding-top: 130px;
    padding-bottom: 130px;

    .contact-us__title {
      .word-T {
        position: relative;

        &::before {
          position: absolute;
          content: '';
          bottom: 0;
          left: 230.5px;
          height: 10px;
          width: 100vw;
          background: linear-gradient(
            90deg,
            #6baac5 -8.33%,
            #2990b1 71.22%,
            #50cbe9 100%
          );
        }
      }
    }

    .contact-us__description {
      margin-top: 17px;

      max-width: 660px;
    }
  }

  &__body {
    position: relative;
    padding-bottom: 63px;

    a {
      color: inherit;
      text-decoration: none;
    }

    &-container {
      max-height: 751px;
      display: flex;
      justify-content: space-between;
      gap: 136px;

      .andrii-card {
        max-width: 384px;
      }

      .form--contact {
        width: 100%;
      }
    }

    .contact-title {
      font-size: 24px !important;
      margin-top: 24px;
      margin-bottom: 16px;
    }

    .contact {
      &-lines {
        margin-top: 24px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 32px;

        .contact-line {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          gap: 16px;

          .contact__link {
            &:hover {
              opacity: 1;
              color: #50c5ea;
            }
          }
        }
      }
    }

    .local-time {
      width: 100%;
      margin: 24px 0;
      display: flex;
      justify-content: flex-start;
      gap: 8px;

      .local-time-value {
        @media (max-width: 991px) {
          line-height: 24px;
        }
      }
    }

    &::after {
      position: absolute;
      content: '';
      bottom: 0;
      width: 100vw;
      height: 10px;
      background: linear-gradient(
        270deg,
        #2a44b6 15.66%,
        #50d6e6 50.78%,
        #2a44b6 99.94%
      );
    }
  }

  .promo-section {
    padding-top: 144px;
    padding-bottom: 137px;
  }
}

@media (max-width: 1350px) {
  .contact-us {
    &__header {
      .container {
        .contact-us__title {
          .word-T {
            &::before {
              left: 201.5px;
            }

            .letter-T {
              &::before {
                height: 65px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1210px) {
  .contact-us {
    &__header {
      .container {
        .contact-us__title {
          .word-T {
            &::before {
              left: 183.5px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1110px) {
  .contact-us {
    &__body {
      &-container {
        gap: 50px;

        .andrii-card {
          padding: 20px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .contact-us {
    &__body {
      &-container {
        .andrii-card {
          justify-content: space-between;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .contact-us {
    &__header {
      padding-bottom: 80px;
    }

    &__body {
      &-container {
        max-height: max-content;
        flex-direction: column;

        .andrii-card {
          margin: 0 auto;
          order: 1;

          @media (max-width: 400px) {
            margin: unset;
          }
          .andrii-photo {
            @media (max-width: 330px) {
              width: 100%;
            }
          }
        }

        .form.form--contact {
          margin-bottom: 0 !important;

          .btn.form-input__button {
            margin: 0 auto;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .contact-us {
    &__header {
      padding-top: 80px;

      .container {
        .contact-us__title {
          .word-T {
            &::before {
              height: 6px;
              bottom: -10px;
              left: 106.5px;
            }
          }

          .letter-T {
            &::after {
              top: 9px;
              height: 6px;
            }
            &::before {
              left: 10px;
              top: 9px;
              width: 6px;
              height: 45px !important;
            }
          }
        }
      }
    }

    &__body {
      padding-bottom: 0;

      &::after {
        display: none;
      }

      .form--contact {
        margin-bottom: 0 !important;

        .form-input__button {
          margin: 0 auto;
        }
      }
    }

    .promo-section {
      padding-top: 52px;
      padding-bottom: 52px;
    }
  }
}

@media (max-width: 400px) {
  .contact-us {
    &__header {
      .contact-us__description {
        display: none;
      }
    }
  }
}
